.content .au_focus {
  position: relative;
  color: #fff;
  font-family: "AUPassataRegular";
  margin-bottom: 2em;
  background-size: cover;

  h1 {
    line-height: 32px;
    font-size: 32px;
    float: left;
    clear: both;
    margin: 0 !important;
    padding: 4px 10px;
    color: #fff;
    font-family: "AUPassataLight";
    background: rgb(51, 51, 51);
    background: rgba(26, 26, 26, 0.75);
    border: 0;
  }
}

.au_focus {
  margin-bottom: 0;

  h1 {
    strong {
      font-family: "AUPassataBold";
    }
    a {
      color: #fff;
      border: 0;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        border: 0;
      }
    }
    + p {
      margin-top: 4px;
    }
  }
  p {
    float: left;
    clear: both;
    margin: 0;
    padding: 0;
    color: #fff;
    padding: 4px 10px;
    background: rgb(51, 51, 51);
    background: rgba(26, 26, 26, 0.75);
    font-size: 16px !important;
    a {
      color: #fff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .au_focus_content {
    margin-left: 24px;
    bottom: 24px;
    position: absolute;
  }

  ul {
    font-size: 16px;
    clear: left;
    margin: 0;
    li {
      float: left;
      clear: left;
      padding: 4px 10px !important;
      margin-top: 4px;
      padding-left: 10px;
      background-image: none;
      line-height: 1em !important;
      margin-bottom: 0 !important;
      a {
        color: #fff !important;
        text-decoration: none;
        border: none !important;
        &:hover {
          text-decoration: underline;
        }
      }
      &:before {
        display: none;

      }
    }
  }
}


@include breakpoint(small only) {
  .au_focus {
    height: initial !important;
    min-height: 90px;
  }
  .au_focus .au_focus_content {
    margin-left: inherit;
    bottom: initial;
    position: relative;
    overflow: auto;
    padding: 1em;
  }
  .content .au_focus h1 {
    line-height: inherit;
  }
}