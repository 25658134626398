// --------------------------------	//
// 		        AUBANNER			//
// --------------------------------	//
.aubanner {

  &.aubanner-2 {
    transition: .2s;
    -webkit-transform: translateZ(0);

    img {
      width:100%;
    }
    .aubanner-content {
      position: relative;
      padding:2rem 2rem 4rem 2rem;
    }
    & a, & h2, & h3, & p {
      color: #fff;
    }
    &:after {
      transition: .2s;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      color: #333;
      text-align: center;
      background: #fff;
      position: absolute;

      right: 1.5em;
      bottom:1.5em;
      line-height: 2.4em;
      content: "≥";
      font-family: "au_icons";
      z-index: 1;
      font-size: 1.2rem;
      margin-top: -16px;
    }
    &:hover:after {
      color: #fff;
    }

    h3 {
      font-size: 115%;
      margin: 0  0 .75em 0;
      font-family: "AUPassataRegular";

    }
    & p, & ul {
      font-family: Georgia;
      margin-top: .75em;
    }
    ul {
      margin-left: 1em;
      li:before {
        color: #fff;
        margin-left: -1.4rem;
        line-height: 2.7em;
      }
    }
    &:before {
      content: " ";
      border: 1px solid #000;
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: #fff;
      opacity: 0;
      transition: .2s;
    }
    &:hover:before {
      opacity: .1;
    }
  }

  position: relative;
  margin-bottom:map-get($grid-column-gutter, medium);
  overflow: hidden;
  letter-spacing: .04rem;
  z-index: 0;

  &.small-margin {
    margin-bottom: 1rem;
  }

  a {
    border-bottom: 0 !important;
    text-decoration: none !important;
    &:hover {
      text-decoration: none !important;
    }
  }

  .aubanner-content-header {
    height: 33%;
    width: 100%;
    position: absolute;
    bottom:0;
    z-index: 2;
    padding: 0 2em;

    &:after, .aubanner-2 .aubanner-content:after {
      transition: .2s;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      color: $primary-color;
      text-align: center;
      background: #fff;
      position: absolute;
      top: 50%;
      right: 1.5em;
      line-height: 2.4em;
      content: "≥";
      font-family: "au_icons";
      z-index: 2;
      font-size: 1.2rem;
      margin-top: -16px;
    }
  }
  &.hastext .aubanner-content-header:after {
    top: auto;
    bottom: 1.5em;
  }


  &.staff .aubanner-content-header:after {
    color: $staff-color;
  }
  &.student .aubanner-content-header:after {
    color: $student-color;
  }
  &.shared .aubanner-content-header:after {
    color: $shared-color;
  }
  &.phd .aubanner-content-header:after {
    color: $phd-color;
  }
  &.red .aubanner-content-header:after {
    color: $red-color;
  }
  &.yellow .aubanner-content-header:after {
    color: $yellow-color;
  }
  &.orange .aubanner-content-header:after {
    color: $orange-color;
  }
  &.gray .aubanner-content-header:after {
    color: $gray-color;
  }

  &.magenta .aubanner-content-header:after {
    color: $magenta-color;
  }

  &:hover .aubanner-content-header:after, &.aubanner-2 {
    background-color: $primary-secondary-color;
    color: #fff;
  }
  &.staff:hover .aubanner-content-header:after, &.aubanner-2.staff {
    background-color: $staff-secondary-color;
  }
  &.student:hover .aubanner-content-header:after, &.aubanner-2.student {
    background-color: $student-secondary-color;
  }
  &.shared:hover .aubanner-content-header:after, &.aubanner-2.shared {
    background-color: $shared-secondary-color;
  }
  &.phd:hover .aubanner-content-header:after, &.aubanner-2.phd {
    background-color: $phd-secondary-color;
  }
  &.red:hover .aubanner-content-header:after, &.aubanner-2.red {
    background-color: $red-secondary-color;
  }
  &.yellow:hover .aubanner-content-header:after, &.aubanner-2.yellow {
    background-color: $yellow-secondary-color;
  }
  &.orange:hover .aubanner-content-header:after, &.aubanner-2.orange {
    background-color: $orange-secondary-color;
  }
  &.gray:hover .aubanner-content-header:after, &.aubanner-2.gray {
    background-color: $gray-secondary-color;
  }
  &.magenta:hover .aubanner-content-header:after, &.aubanner-2.magenta {
    background-color: $magenta-secondary-color;
  }
  &.aubanner-2.white, &.aubanner-2.lightgray {
    background-color: #fff;
    a,p,h2,h3,li:before {
      color: #333;
    }
  }
  &.aubanner-2.lightgray {
    background-color: #f0efef;
  }

  .aubanner-content-header-pos {
    height: 100%;
    position: relative;
    display: table;
    margin-right: 4em;

  }

  h2, p {
    color: $primary-color;
  }
  h2 {
    font-family: "AUPassataBold";
    font-size: 1.4vw;
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    line-height: 1.2;

  }
  &:not(.hastext) {
    p {display: none}
  }


  @include breakpoint(small only) {
    & h2 {
      font-size: 4.8vw;
    }
    &:not(.hastext) {
      & .aubanner-content, & .aubanner-content-header {
        height: 33% ;
      }
    }
  }
  @include breakpoint(medium only) {
    h2 {
      font-size: 2.4vw;
    }
  }
  @include breakpoint(xlarge up) {
    h2 {
      font-size: 20px;
    }
  }

  p {
    line-height: 1.4;
    margin-top: 1em;
  }

  &:hover {
    .aubanner-content-overlay {
      opacity: .92;
    }
  }

  .aubanner-content {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 33%;
    transition: .2s ease-in-out;
    overflow: hidden;
    bottom: 0;
    background-position: bottom left;
    background-size: 100%;
    -webkit-transform-style: preserve-3d;

    .aubanner-content-overlay {
      background-color: rgba(255, 255, 255, .90);
      height: 100%;
      transition: .2s;
    }

    .aubanner-content-text {
      bottom: 0;
      position: absolute;
      margin-right: 60px;
      display: table-cell;
      vertical-align: middle;
    }
  }
  &.hastext, &.overlay_full {
    .aubanner-content {
      height: 100%;
    }
    .aubanner-content-header {
      height: auto;
    }
    .aubanner-content-header-pos {
      display: block;
    }
  }

  &.hastext.overlay_partial_33 {
    .aubanner-content {
      height: 33%;
    }
    .aubanner-content-header {
      height: auto;
    }
    .aubanner-content-header-pos {
      display: block;
    }
  }

  &.overlay_full:not(.hastext) .aubanner-content-header {
    margin-bottom: 1em;
  }

  &.overlay_partial_50 {
    .aubanner-content {
      height: 50%;
    }
    .aubanner-content-header {
      height: 50%;
    }
  }

  &.hastext.overlay_partial_50 {
    .aubanner-content {
      height: 50%;
    }
    .aubanner-content-header {
      height: auto;
    }
    .aubanner-content-header-pos {
      display: block;
    }
  }



  &.dark {
    .aubanner-content-overlay {
      background-color: rgba($primary-color, .90);
    }

    &.student .aubanner-content-overlay {
      background-color: rgba($student-color, .90);
    }

    &.shared .aubanner-content-overlay {
      background-color: rgba($shared-color, .90);
    }

    &.phd .aubanner-content-overlay {
      background-color: rgba($phd-color, .90);
    }

    &.staff .aubanner-content-overlay {
      background-color: rgba($staff-color, .90);
    }
    &.yellow .aubanner-content-overlay {
      background-color: rgba($yellow-color, .90);
    }
    &.orange .aubanner-content-overlay {
      background-color: rgba($orange-color, .90);
    }
    &.red .aubanner-content-overlay {
      background-color: rgba($red-color, .90);
    }
    &.magenta .aubanner-content-overlay {
      background-color: rgba($magenta-color, .90);
    }
    &.gray .aubanner-content-overlay {
      background-color: rgba($gray-color, .90);
    }

    h2, p {
      color: #fff;
    }
    &:hover:after {
      background-color: rgba(0, 0, 0, .5);
    }
  }
  &.light {
    .aubanner-content-overlay {
      background-color: rgba(lighten($primary-color, 72%), .85);
    }
    &.student {
      .aubanner-content-overlay {
        background-color: rgba(lighten($student-color, 35%), .85);
      }
      h2, p {
        color: $student-secondary-color;
      }
      &:after {
        color: $student-secondary-color;
      }
      &:hover:after {
        background-color: $student-secondary-color;
        color: #fff;
      }
    }
    &.shared {
      .aubanner-content-overlay {
        background-color: rgba(lighten($shared-color, 35%), .85);
      }
      h2, p {
        color: $shared-secondary-color;
      }
      &:after {
        color: $shared-secondary-color;
      }
      &:hover:after {
        background-color: $shared-secondary-color;
        color: #fff;
      }
    }
    &.phd {
      .aubanner-content-overlay {
        background-color: rgba(lighten($phd-color, 35%), .85);
      }
      h2, p {
        color: $phd-secondary-color;
      }
      &:after {
        color: $phd-secondary-color;
      }
      &:hover:after {
        background-color: $phd-secondary-color;
        color: #fff;
      }
    }
    &.staff {
      .aubanner-content-overlay {
        background-color: rgba(lighten($staff-color, 35%), .85);
      }
      h2, p {
        color: $staff-secondary-color;
      }
      &:after {
        color: $staff-secondary-color;
      }
      &:hover:after {
        background-color: $staff-secondary-color;
        color: #fff;
      }
    }
    &.magenta {
      .aubanner-content-overlay {
        background-color: rgba(lighten($magenta-color, 35%), .85);
      }
      h2, p {
        color: $magenta-secondary-color;
      }
      &:after {
        color: $magenta-secondary-color;
      }
      &:hover:after {
        background-color: $magenta-secondary-color;
        color: #fff;
      }
    }

  }
  &.overlay_none {
    .aubanner-content {
      display: none;
    }
  }


  img:not(.frontEndEditIcons) {
    width: 100%;
    position: relative;
    clip-path: inset(-1px -1px 1px -1px);
  }
  img.frontEndEditIcons {
    z-index: 3;
    position: absolute;
    bottom: 0;
  }
}

// --------------------------------	//
// 	    Useful links (widget)       //
// --------------------------------	//

.main ul.useful-links {
  overflow:auto;

  li {
    display: block;
    width: 50%;
    font-family: "AUPassataBold";
    float: left;
    color: #fff;
    font-size: 125%;
    text-align: center;
    background-color: $student-secondary-color;
    margin-bottom: 2px !important;
    padding: 0;
    position: relative;
    height: 99px;
    transition: .1s;

    &:nth-child(odd) {
      border-right: 2px solid;
    }

    &:before {
      content: "";
    }
    &:hover {
      background-color: lighten($student-secondary-color, 3%);
    }
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
    color: #fff;
    border: 0;
    padding-top: 3.6em;

    &:before {
      font-size: 300%;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      width: 100%;
      font-family: "au_icons";
      color: $student-color;
    }

    &:hover {
      border:0
    }
  }

  .icon-blackboard a:before {
    content: "B";
  }

  .icon-mail a:before {
    content: "M";
  }

  .icon-book a:before {
    content: "b";
  }

  .icon-apply a:before {
    content: "a";
  }
}

.cludo-banner .aubanner {
  margin: -15px;
}
