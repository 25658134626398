$font-regular: "AUPassataRegular";
$font-bold: "AUPassataBold";
$font-icons: "au_icons";

$socialfontsize: 14px;
$socialfontsize_1320: 12px;
$socialfontpadding: 8px;
$socialtextindent: 10px;
$socialtextindent_1320: 6px;

div.au_social_sharebox {
    position: relative;
}

ul.au_social_sharing {

    font-family: $font-regular;
    text-transform: uppercase;
    color: #fff;
    clear: both;
    margin-bottom: 0;

    &:after {
        display: block;
        content: '';
        clear: both;
    }

    &.horizontal {
        > li {
            float: left;

            > ul {
                position: absolute;
                bottom: 2 * $socialfontpadding + $socialfontsize;

                li {

                    &:nth-child(even) {
                        background-color: #004e93;
                    }

                    &:nth-child(odd) {
                        background-color: #003d73;
                    }

                    a {
                        text-indent: $socialtextindent;
                    }
                }
            }
        }

        &.services-2 {
            li {
                width: 50%;
            }
        }

        &.services-3 {
            li:first-child, li:nth-child(3) {
                width: 33%;
            }

            li:nth-child(2) {
                width: 34%;
            }
        }

        &.services-4 {
            li {
                width: 25%;
                @include breakpoint(small only) {
                    width:50%;
                }
            }
        }

        &.services-5 {
            li {
                width: 20%;
            }
        }
    }

    &.icons {
        li {
            a:after {
                color: #fff;
                opacity: 0.8;
                font-family: 'au_icons';
                font-size: 18px;
                position: absolute;
                top: 6px;
                right: 6px;
            }

            &.email {
                > a {

                    &:after {
                        content: '+';
                    }

                    &.active:after {
                        content: '-'
                    }
                }
            }

            &.facebook > a:after {
                content: 'f';
            }

            &.twitter > a:after {
                content: 't';
            }

            &.linkedin > a:after {
                content: 'i';
            }

            &.gmail > a:after, &.outlook > a:after, &.outlookcom > a:after, &.yahoo > a:after {
                content: '';
                text-transform: none;
            }
        }
    }

    &.spacing {
        > li {
            margin-bottom: 8px;
        }
    }

    li {
        font-size: $socialfontsize;
        padding-left: 0;
        background-image: none;
        margin: 0;
        line-height: 1;
        list-style: none;

        a {
            border: none !important;
            text-indent: $socialtextindent;
            padding: $socialfontpadding 0;
            color: #fff !important;
            display: block;

            &:hover {
                text-decoration: underline;
            }
        }

        > a {
            position: relative;
        }

        > ul {
            margin: 0;
            display: none;
            width: 100%;

            li {
                &:nth-child(odd) {
                    background-color: #004e93;
                }

                &:nth-child(even) {
                    background-color: #003d73;
                }

                a {
                    text-indent: 2 * $socialtextindent;
                }
            }
        }

        &.email {
            background-color: #003d73;
        }

        &.facebook {
            background-color: #3b5998;
        }

        &.twitter {
            background-color: #00aced;
        }

        &.linkedin {
            background-color: #007bb6;
        }
    }

    @media only screen and (max-width: 1320px) {

        #au_content & {

            &.horizontal {
                li {
                    font-size: $socialfontsize_1320;

                    a {
                        text-indent: $socialtextindent_1320;
                    }
                }

                > li {
                    > ul {
                        bottom: 2 * $socialfontpadding + $socialfontsize_1320;
                    }
                }

                &.icons {
                    li {
                        a:after {
                            font-size: 16px;
                            top: 5px;
                            right: 3px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1100px) {

        #au_content & {

            &.horizontal {

                &.icons {
                    li {
                        a:after {
                            display: none;
                        }
                    }
                }
            }

        }
    }
}

$icon-size-small: 30px;
$icon-size-medium: 50px;
$icon-size-large: 70px;
ul.au_social_linking {

    font-family: $font-icons;
    margin: 0;

    .footer & li:before {content: '';}

    &.small {
        font-size: $icon-size-small;

        li {
            width: $icon-size-small;
            height: $icon-size-small;
        }
    }

    &.medium {
        font-size: $icon-size-medium;

        li {
            width: $icon-size-medium;
            height: $icon-size-medium;
        }
    }

    &.large {
        font-size: $icon-size-large;

        li {
            width: $icon-size-large;
            height: $icon-size-large;
        }
    }

    li {
        float: left;
        padding-left: 0 !important;
        margin: 0;
        line-height: 1;
        text-align: left;
        overflow: hidden;

       .main & a {
            display: block;
            position: relative;
            border-bottom: none;

            &:after {
                position: absolute;
                top: 0;
                left: 0;
                text-decoration: none;
            }
        }

        &.facebook a {

            color: #3b5998 !important;

            &:after {
                content: 'F';
            }
        }

        &.twitter a {

            color: #00aced !important;

            &:after {
                content: 'T';
            }
        }

        &.linkedin a {

            color: #007bb6 !important;

            &:after {
                content: 'I';
            }
        }

        &.youtube a {

            color: #bb0000 !important;

            &:after {
                content: 'Y';
            }
        }

        &.vimeo a {

            color: #aad450 !important;

            &:after {
                content: 'V';
            }
        }

        &.instagram a {

            color: #517fa4 !important;

            &:after {
                content: 'S';
            }
        }
    }

}

