/*############################*/
/* --- CSS STYLED CONTENT --- */
/*############################*/
.csc-textpic {overflow:hidden;}
.tx-felogin-pi1 label{display:block;}
.tx-felogin-pi1 legend{display:none;}
/* Captions */
div.csc-textpic-caption-c .csc-textpic-caption{text-align:center;}
div.csc-textpic-caption-r .csc-textpic-caption{text-align:right;}
div.csc-textpic-caption-l .csc-textpic-caption{text-align:left;}
.csc-textpic-caption{font-size:90%;line-height:130%;color:#818181;padding:0.4em 0;}
/* Needed for noRows setting */
div.csc-textpic div.csc-textpic-imagecolumn{float:left;display:inline;}
/* Border just around the image */
div.csc-textpic-border div.csc-textpic-imagewrap .csc-textpic-image img,div.csc-textpic-border div.csc-textpic-single-image img{border:2px solid black;padding:0px 0px;}
div.csc-textpic-imagewrap{padding:0;}
#au_content div.csc-textpic img,#au_content .csc-textpic-imagewrap a{border:none;}
/* div:This will place the images side by side */
div.csc-textpic div.csc-textpic-imagewrap div.csc-textpic-image{float:left;}
/* UL:This will place the images side by side */
div.csc-textpic div.csc-textpic-imagewrap ul{list-style:none;margin:0;padding:0;}
div.csc-textpic div.csc-textpic-imagewrap ul li{float:left;margin:0;padding:0;}
.main ul li.csc-textpic-image:before {
  content: '' !important;
}
/* DL:This will place the images side by side */
div.csc-textpic div.csc-textpic-imagewrap dl.csc-textpic-image{float:left;}
div.csc-textpic div.csc-textpic-imagewrap dl.csc-textpic-image dt{float:none;}
div.csc-textpic div.csc-textpic-imagewrap dl.csc-textpic-image dd{float:none;}
div.csc-textpic div.csc-textpic-imagewrap dl.csc-textpic-image dd img{border:none;}
/* FE-Editing Icons */
dl.csc-textpic-image{margin:0;}
dl.csc-textpic-image dt{margin:0;display:inline;}
dl.csc-textpic-image dd{margin:0;}
/* Clearer */
div.csc-textpic-clear{clear:both;}
/* Margins around images:*/
/* Pictures on left,add margin on right */
div.csc-textpic-left div.csc-textpic-imagewrap .csc-textpic-image,
div.csc-textpic-intext-left-nowrap div.csc-textpic-imagewrap .csc-textpic-image,
div.csc-textpic-intext-left div.csc-textpic-imagewrap .csc-textpic-image{display:inline;/* IE fix for double-margin bug */
  margin-right:10px;}
/* Pictures on right,add margin on left */
div.csc-textpic-right div.csc-textpic-imagewrap .csc-textpic-image,
div.csc-textpic-intext-right-nowrap div.csc-textpic-imagewrap .csc-textpic-image,
div.csc-textpic-intext-right div.csc-textpic-imagewrap .csc-textpic-image{display:inline;/* IE fix for double-margin bug */
}
/* Pictures centered,add margin on left */
div.csc-textpic-center div.csc-textpic-imagewrap .csc-textpic-image{display:inline;/* IE fix for double-margin bug */
  margin-left:10px;}
div.csc-textpic div.csc-textpic-imagewrap .csc-textpic-image .csc-textpic-caption{margin:0;}
div.csc-textpic div.csc-textpic-imagewrap .csc-textpic-image img{margin:0;}
/* Space below each image (also in-between rows) */
div.csc-textpic div.csc-textpic-imagewrap .csc-textpic-image{margin-bottom:5px;padding-left:0 !important;}
div.csc-textpic-equalheight div.csc-textpic-imagerow{margin-bottom:5px;display:block;}
div.csc-textpic div.csc-textpic-imagerow{clear:both;}
div.csc-textpic div.csc-textpic-single-image img{margin-bottom:5px;}
/* No margins around the whole image-block */
div.csc-textpic div.csc-textpic-imagewrap .csc-textpic-firstcol{margin-left:0px !important;}
div.csc-textpic div.csc-textpic-imagewrap .csc-textpic-lastcol{margin-right:0px !important;}
/* Add margin from image-block to text (in case of "Text w/ images") */
div.csc-textpic-intext-left div.csc-textpic-imagewrap,
div.csc-textpic-intext-left-nowrap div.csc-textpic-imagewrap{margin-right:20px !important;}
div.csc-textpic-intext-right div.csc-textpic-imagewrap,
div.csc-textpic-intext-right-nowrap div.csc-textpic-imagewrap{margin-left:20px !important;}
/* Positioning of images:*/
/* Above */
div.csc-textpic-above div.csc-textpic-text{clear:both;}
/* Center (above or below) */
div.csc-textpic-center{text-align:center;/* IE-hack */}
div.csc-textpic-center div.csc-textpic-imagewrap{margin:0 auto;}
div.csc-textpic-center div.csc-textpic-imagewrap .csc-textpic-image{text-align:left;/* Remove IE-hack */}
div.csc-textpic-center div.csc-textpic-text{text-align:left;/* Remove IE-hack */}
/* Right (above or below) */
div.csc-textpic-right div.csc-textpic-imagewrap{float:right;}
div.csc-textpic-right div.csc-textpic-text{clear:right;}
/* Left (above or below) */
div.csc-textpic-left div.csc-textpic-imagewrap{float:left;}
div.csc-textpic-left div.csc-textpic-text{clear:left;}
/* Left (in text) */
div.csc-textpic-intext-left div.csc-textpic-imagewrap{float:left;}
/* Right (in text) */
div.csc-textpic-intext-right div.csc-textpic-imagewrap{float:right;}
/* Right (in text,no wrap around) */
div.csc-textpic-intext-right-nowrap div.csc-textpic-imagewrap{float:right;clear:both;}
/* Hide from IE5-mac. Only IE-win sees this. \*/
* html div.csc-textpic-intext-right-nowrap .csc-textpic-text{height:1%;}
/* End hide from IE5/mac */
/* Left (in text,no wrap around) */
div.csc-textpic-intext-left-nowrap div.csc-textpic-imagewrap{float:left;clear:both;}
/* Hide from IE5-mac. Only IE-win sees this. \*/
* html div.csc-textpic-intext-left-nowrap .csc-textpic-text{height:1%;}
/* End hide from IE5/mac */
div.csc-textpic div.csc-textpic-imagerow-last{margin-bottom:0;}
/* Browser fixes:*/
/* Fix for unordered and ordered list with image "In text,left" */
.csc-textpic-intext-left ol,.csc-textpic-intext-left ul{overflow:auto;height:1%;}
.csc-textpic-imagewrap {
  max-width: 100%;
  overflow-x: auto;
}
.csc-textpic-imagecolumn {
  max-width: 100%!important;
}
.csc-textpic-image {
  max-width: 100%!important;
  padding-right: 10px;
  margin: 0 0 10px 0!important;
}
.csc-textpic-intext-left-nowrap .csc-textpic-image, .csc-textpic-intext-left .csc-textpic-image, .csc-textpic-left .csc-textpic-image {
  padding-right: 0!important;
}