$font-regular: "AUPassataRegular";
$font-bold: "AUPassataBold";
$font-legend: "Helvetica Neue", Arial, Helvetica, sans-serif;
$media-break-max: 1024px;
$gfx-path: "../img/news/";
$popupwidth: 732px;

/* POPUP */

.au_news_popup {

    > div:first-child {
        position: relative; overflow: auto; outline: none;
    }

    &.ipad {
        bottom: 0; overflow: auto; top: 0;
    }

    &.social {
        bottom: 30px;
    }

    &.fade {
        z-index: 99;
    }

    position: fixed;
    width: $popupwidth;
    height: auto;
    top: 42px;
    bottom: 0;
    left: 50%;
    margin-left: -366px;
    z-index: 101;
    background: #fff;
    -webkit-overflow-scrolling: touch;

    @media only screen and (max-width: $media-break-max) {
        width: 100%; margin: 0; left: 0; top: 0; bottom: 0; overflow: auto;

        &.social {
            bottom: 0;
        }
    }
}

@media print {
    .au_news_popup {
        position: static;
        width: auto;
        margin-left: 0;

        > div:first-child {
            overflow: visible;
            height: auto !important;
        }

    }

    .au_news_overlay, .au_news_overlay_close, .au_news_socialbar, .au_news-single-print, .au_news-single-language {
        display: none;
    }

}

div.au_news_overlay {

    .ipad & { position: static; }

    background-color: $primary-color;
    height: 42px;
    padding: 12px 0;
    position: fixed;
    width: $popupwidth;
    top: 0;

    @media only screen and (max-width: $media-break-max) {
        width: 100%; position: static; font-size: 150%; height: 70px; padding: 20px 0;
    }
}

div.au_news_overlay_wrapper {
    padding: 1.5rem;
}

div.au_news_socialbar {
    position: fixed; bottom: 0; width: $popupwidth; height: 30px; background: #000;

    @media only screen and (max-width: $media-break-max) {
        position: static; width: 100%;
    }
}

h1.au_news_overlay_header {
    font-size: 2.8rem; line-height: 1em; padding: 18px 0; text-transform: uppercase;
}

.au_news_overlay_close {
    position: absolute; width: 36px; height: 36px; background: url('#{$gfx-path}fancybox_sprite.png'); z-index: 101; top: -18px; right: -18px; cursor: pointer;

    @media only screen and (max-width: $media-break-max) {
        width: 50px; height: 50px; background: url('#{$gfx-path}close_mobile.png'); position: fixed; z-index: 101; top: 90px; right: 30px; background-size: 50px 50px;
    }
}

/* LIST */

.twoone .column .au_news-list-container .column {
    .au_news-list3-item {
        min-height: 225px;
    }
    .au_news-list4-item {
        min-height: 225px;
    }
    .au_news-list5-item {
        min-height: 140px;
    }
}

.au_news-list-header {
    position: relative;
}

.au_news-list-container {
    .au_focus {
        max-width: 670px; height: 270px; background-repeat: no-repeat;
        li {
            background-color: rgba(0,61,133,0.75) !important;
        }
        &.au_focus_small {
            max-width: 330px; height: 132px;
        }
    }

    div.main & .au_focus.au_focus_small {
        h1 {
            font-size: 16px; line-height: 1; font-family: $font-regular; text-transform: none;
        }
    }
}

div.au_news-list1-item, div.au_news-list2-item {
    div.au_news-list-item-img {
        float: none; overflow: hidden;
    }
    div.au_news-list-item-text {
        margin-left: 0;
    }
    h2 {
        margin: 0.5em 0; font-family: $font-bold; font-size: 200%;
    }
    margin-bottom: 2em;
}

div.au_news-list3-item {
    div.au_news-list-item-text {
        margin-left: 150px;
    }
}

div.au_news-list5-item {
    div.au_news-list-item-text {
        margin-left: 0;
    }
}

div.au_news-header-list {
    color: #999;
    ul {
        margin: 0 !important;
    }
}

div.au_news-list3-item, div.au_news-list4-item, div.au_news-list5-item, div.au_news-header-list {
    clear: both; margin-bottom: 0; overflow: auto; padding: 1.36364rem 0;

    &:last-child {
        border: none;
        padding-bottom: 1rem;
    }
}

div.au_news-list-item:last-child {
    border: none;
}

p.au_news-legend {
    font-family: $font-legend; color: #636363; margin: 0 0 0.5em 0;
}

.au_news_popup {
    p.au_news-legend {
        clear: both;
    }
}

span.au_news-single-print, span.au_news-single-language {
    float: right;
    margin-right: 1em;
    font-family: $font-regular;

    a {
        color: #fff;
    }
}

div.content, div#au_related_content {

    div.au_news-list3-item h2, div.au_news-list4-item h2, div.au_news-list5-item h2, .au_news-eventlist1-item a {
            line-height: 1.2em; margin: 3px 0 6px 0; font-family: $font-bold; font-size: 1.4rem;
    }

    .csc-header.relative {
        > ul {
            > li {
                float: right; padding: 0; margin: 0; width: 29px; min-height: 29px; margin-left: 6px; text-indent: -9999px; cursor: pointer; background-repeat: no-repeat; background-size: initial;

                > div {
                    text-indent: 0; cursor: auto; margin-top: 29px; color: #fff; padding: 1em; display: none; position: relative;

                    h2 {
                        margin: 0 0 .5em 0; padding: 0;
                    }

                    a {
                        color: #fff; border-bottom: 1px dotted #fff;
                    }

                    .close {
                        width: 16px; height: 16px; background-image: url('#{$gfx-path}news_sprite.png'); background-position: -102px -31px; cursor: pointer; position: absolute; top: -22px; left: 6px;
                    }
                }

                &.rss {

                    background-color: #fe8d22; background-image: url('#{$gfx-path}news_sprite.png'); background-position: -30px -57px;
                }

                &.add {
                    display: none; background-color: #47bd26; background-image: url('#{$gfx-path}news_sprite.png'); background-position: 0 0;
                }

                &.edit {
                    background: #47bd26; text-indent: 0; width: 60px; color: #fff; text-align: right;

                    > span {
                        font-family: $font-regular; font-size: 14px; position: absolute; left: 12px; top: 6px;
                    }
                }

                &.wide {
                    width: 220px;

                    > div {

                        input[type=text] {
                            width: 188px;
                        }

                        label {
                            color: #fff;
                        }

                        display: block;
                    }

                    &.rss {
                        background-position: 161px -57px;
                    }

                    &.edit {
                        > span {
                            display: none;
                        }
                    }
                }
            }

            position: absolute; right: 0; top: 0; margin: 0;
        }

        position: relative;
        clear: both;
    }
}

/* SINGLE */

.au_newsevent-time-text {
    font-weight: bold;
}

.au_newsevent-from-date .au_news_events_weekday {
    text-transform: capitalize;
}

.au_news-single-news-permalink {
    color: #8c8c8c; font-size: 85%; margin: 1em 0; clear: both;
}

.au_news_content {

    a {
        border-bottom: 1px dotted #003d73;

        &:hover {
            border-bottom: 1px solid #000; text-decoration: none;
        }
    }

    font-size: 1.25rem;

    p {
        font-size: 1.25rem;
    }

    .external & {
        padding: 1em; background: #f0efef;
    }

    @media only screen and (max-width: $media-break-max) {
        font-size: 150%;
    }

    ol {
        li {
            list-style-position: inside !important;
        }
    }
}

@media print {
    .au_news_content {
        font-size: 100%;
    }
}

#disqus_thread {
    margin-top: 2em;
}

/* EVENTS */

.au_news-eventlist1-item {

    margin-bottom: 3em;

    > div {
        display: table-cell;
        position: relative;
    }

    .au_news_eventlist1-calendar {

        span {
            position: absolute;
            display: block;
            text-align: center;
            width: 52px;

            &.multiple {
                display: none;
            }
        }

        width: 75px; height: 86px; color: #939292; text-transform: uppercase; font-family: $font-regular; background: url('//www.au.dk/typo3conf/ext/au_config/res/au_newsevent/au_events_list-bg.gif') no-repeat; padding-top: 6px;

        .au_news_events_weekday {
            font-size: 13px;
            top: 5px;
            left: 0;
        }

        .au_news_events_date {
            font-size: 30px;
            line-height: 1;
            top: 24px;
            left: 0;
        }

        .au_news_events_month {
            font-size: 13px;
            top: 49px;
            left: 0;
        }

        .au_news_events_date, &.multiple .au_news_events_date_end {
            font-weight: bold; font-family: $font-bold; letter-spacing: -2px;
        }

        &.multiple {

            span {
                display: block;
                width: 34px;
            }

            .au_news_events_weekday {
                font-size: 9px; top: 2px;
            }

            .au_news_events_date {
                top: 20px;
                font-size: 24px; line-height: 14px; width: 31px;
            }

            .au_news_events_month {
                top: 34px;
                font-size: 9px;
            }

            .au_news_events_weekday_end {
                top: 34px; left: 31px; font-size: 9px;
            }

            .au_news_events_date_end {
                top: 51px;
                left: 31px;
                font-size: 24px; line-height: 14px; width: 31px;
            }

            .au_news_events_month_end {
                top: 65px;
                left: 31px;
                font-size: 9px;
            }

            background: url('//www.au.dk/typo3conf/ext/au_config/res/au_newsevent/au_events_list_multi-bg.png') no-repeat; padding-top: 2px;
        }
    }

    .au_news_eventlist1-text, .au_news-header-list {
        span {
            color: #666;
        }
    }

}

.au_news-eventlist2-item {
    clear: both; min-height: 100px; margin-bottom: 14px; position: relative;

    .au_padding {
        background: #f3f3f3 !important; padding: 14px; padding-bottom: 10px; min-height: 101px;
    }

    &.au_event-featured {
        .au_padding {
            background: #fff !important; -moz-box-shadow: 0 0 5px #888; -webkit-box-shadow: 0 0 5px #888; box-shadow: 0 0 5px #888; margin-left: 5px !important; z-index: 999; padding: 24px !important; margin-right: 0 !important;
            &:hover {
                padding: 23px !important;
            }
        }

        margin-right: 2px !important;
    }

    .savebutton {

        a {
            color: #fff !important; border: none !important;
        }

        position: absolute; display: none; right: 10px; top: 10px; font-family: $font-bold; cursor: pointer; text-align: center !important; font-size: 15px;
    }

    &:hover .savebutton {
        display: block;
    }

    .au_news_eventlist2-text {
        span {
            color: #838383;
        }
    }

    .au_news_events_time {
        color: #333 !important;
    }

    .au_news_eventlist2-calendar {

        .au_news_events_weekday, .au_news_events_month {
            font-size: 18px;
        }

        .au_news_events_date {
            font-size: 24px; font-family: $font-bold; line-height: 1em;
        }

        span {
            display: block; text-align: center; width: 52px; margin-left: 42px;
        }

        &.show-year {
            span {
                display: block; text-align: center; width: 60px; margin-left: 28px;
            }
        }


        width: 96px; height: 78px; background: #9b9b9b; float: left; margin-right: 1em; text-shadow: 0px 1px 3px #616161; filter: dropshadow(color=#616161, offx=0, offy=1); text-transform: uppercase; font-family: $font-regular; color: #fff;
    }

    div.main & h2 {
        font-size: 18px; margin: 0 0 4px 0;

        a {
            border: none;

            &:hover {
                border-bottom: 1px solid #003d73;
            }
        }
    }
}

/* BUSINESS CARD */

.au_news_card {

    border: 1px solid #ccc;
    width: 300px;
    font-size: 85%;
    padding: 1em;
    margin: .5em 0 1em 0;

    h2 {
        margin-top: 0;
        font-size: 130%;
    }

    &.left {
        float: left;
        margin: .5em 1em 1em 0;
    }

    &.right {
        float: right;
        margin: .5em 0 1em 1em;
    }

    &.loading {
        min-height: 60px;
        background-image: url('//cmsdesign.au.dk/design/2008/graphics/gsa/ajax-loader.gif');
        background-repeat: no-repeat;
        background-position: center center;
    }

}

label.checked-partial {
    text-indent: 20px; background-image: url('#{$gfx-path}news_sprite.png'); background-repeat: no-repeat; background-position: -29px -2px; background-size: initial;

    input:checked {
        display: none;
    }
}

/* COLLAGE */
.featured-content {
    #news1, #event1, #event2 {
        img {
            display: none;
        }
    }

    #news1, #news2, #news3, #news4 {
        .aubanner-content {
            p:last-child {
                span:last-child {
                    display: none;
                }
            }
        }
    }

    .news-event {
        .aubanner-content {

            p:nth-last-child(2) {
                margin-bottom: 4rem;
            }

            p:last-child {
                position: absolute;
                bottom: 1rem;

                span {
                    font-family: "AUPassataRegular";
                    font-size: 1rem;
                    display: block;

                    &:last-child {
                        margin-right: 6rem;
                    }
                }
            }
        }
    }
}

/* CALENDAR */

#maincontent {
    .columns.onetwo:first-child {
        .column {
            &:first-child {
                .au_padding {
                    overflow: hidden;
                }
            }
        }
    }
}

.au_newsroom_calendar_categories {
    ul {
        margin-top: 0 !important; margin-bottom: 1em !important;

        &.category-child {
            margin-bottom: 0 !important;
            display: none;
        }

        .category-child-of-0 {
            display: block !important;
        }

        li {
            background-image: none !important; padding: 0 !important; margin: 0 !important; position: relative;

            span {
                position: absolute; top: 8px; right: 6px; width: 12px; height: 12px; display: block; background-image: url('#{$gfx-path}news_sprite.png'); background-position: -15px -45px; background-size: inherit; cursor: pointer;
                &.folded-out {
                    background-position: -1px -45px;
                }
            }

            &.category-parent {
                ul {
                    display: none;
                }
            }
        }

        label {
            padding: 0.3em 0; margin: 0; display: block; font-weight: normal;
        }
    }

    input[type='checkbox'] {
        margin: 0 0.6em 0 0;
    }
}

.au_newsroom-addevent {
    a {
        color: #fff !important; border: 0 !important;
    }

    position: absolute; right: 15px; top: -60px; font-family: $font-bold; font-size: 16px; text-transform: uppercase; cursor: pointer; color: #ffffff; padding: 7px 12px; background-color: #4ac026;
}

.temp-block {
    background: #ccc;
    height: 200px;
    margin-bottom: 30px;
}

/*##############*/
/* --- NEWS --- */
/*##############*/
/* --- GENERAL ELEMENTS --- */
.au_news-legend{margin-bottom:0;color:#666;font-size:90%;text-transform:uppercase;}
.main .au_newsroom_calendar_categories ul li:before {content:'';}
/* --- LISTS --- */
.main .au_news-list-item h2,.main .au_news-list3-item h2{font-size:1.4rem;font-weight:normal;line-height:1.2em;margin:3px 0 6px 0;}
.related .au_news-list-item h2,.related .au_news-list3-item h2,.related .au_news-list2-item h2{padding:0;font-weight:normal;font-size:125%;margin:3px 0 6px 0;}
.au_news-list-item img,.au_news-list2-item img{float:left;clear:left;margin:0 12px 6px 0;}
.au_news-list-item,.au_news-list3-item{margin-bottom:1em;overflow:auto;padding:1.36364rem 0;}
div.au_news-list-item-img{float:left;}
div.au_news-list-item-img img{width:auto;}
div.au_news-list-item-text{margin-left:108px;}
div.au_news-list-item{clear:both;}
.main div.au_news-header-list ul li{margin-left:0;}
/* --- LATEST --- */
div.au_news-latest-container div.au_news-list-item-text{margin-left:168px;}
/* --- SINGLES --- */
div.au_news-single-item .au_news-legend{padding-bottom:1em;}
div.au_news-single-item blockquote{width:200px;float:right;font-family:Georgia;font-size:150%;border:0;padding:0 0 0 36px;line-height:1.2em;font-style:italic;font-weight:bold;background:url("https://cdn.au.dk/2015/au/graphics/sprite_specials.png") -167px -276px no-repeat;}
div.au_news-single-item blockquote p{margin-bottom:0;}
div.au_news-single-item blockquote p + p{font-size:11px;float:right;clear:right;background:none;font-family:"AUPassataRegular";color:#666;font-style:normal;font-weight:normal;margin-top:0.5em;width:200px;}
#au_content div.au_news-single2-img-main a,#au_content .news-set1-img1all a,#au_content .au_news-single-sub-images a{border:0;}
p.au_news-single-subheader{font:18px "AUPassataRegular",verdana !important;}
.au_news-single-imgcaption,.au_news-single2-imgcaption{clear:left;float:left;margin-right:22px !important;width:200px;font-size:90%;color:#818181;padding-bottom:1.4em;}
.au_news-single-sub-images{float:left;width:210px;padding-right:12px;}
.au_news-single-img img{float:left;clear:left;margin:0 18px 0.5em 0;}
.au_news-single-additional-info dt{font-weight:bold;padding-top:1em;}
.au_news-single-additional-info dt:first-child{padding-top:0;}
.main .au_news-single-additional-info dd{margin-left:0;padding:inherit;}
.au_news-single-additional-info{padding:2em;border-top:6px solid #073D78;margin-top:2em;clear:both;}
.au_news-single2-item .au_news-single2-imgcaption{float:none;margin:0;padding:0.4em 0;width:100%;}
.au_news-single2-img-main,.news-set1-img1all{margin-bottom:1em;}
.au_news-single-news-category{color:#8C8C8C;font-size:85%;margin:1em 0;}
/* --- ARCHIVE --- */
.amenu-act,.au_news-amenu-item-year{font-weight:bold;}
/* --- PAGEBROWSER --- */
.au_news-list-browse a,.au_pagebrowser a{display:block;float:left;border:1px solid #8C8C8C !important;margin-right:4px; margin-bottom:4px; padding:0 0.4em; text-decoration: none !important;}
#au_content .au_news-list-browse a:hover,.au_pagebrowser a:hover{border:1px solid #003d85;}
.au_news-list-browse,.au_pagebrowser{padding-bottom:1em;overflow:auto;}

/* --- News events --- */
.au_news_events {
    color: #666;
    a {
        font-family: "AUPassataBold";
        font-size: 1.4rem;
    }
    dt {
        display: block;
        width: 75px;
        height: 86px;
        text-transform: uppercase;
        //color: #939292;
        font-family: "AUPassataRegular";
        padding-top: 6px;
        span {
            width: 53px;
            display: block;
            text-align: center;
            font-size: 14px;
        }
    }
    dd {
        position: relative;
        top: -86px;
        margin: 0 0 -40px 5em;
        p {
            color: #212121;
        }
    }
    .au_news_events_date {
        font-size: 32px;
        //color: #8d8c8c;
        font-family: "AUPassataBold";
        line-height: 28px;
        letter-spacing: -2px;
        margin-left: -1px;
        margin-bottom: -5px;
    }
}

.au_news_events_time {
    font-weight: bold;
}

table.au_newsevent-event {
    background: #F0EFEF;
    margin-bottom: 1em;
    width: 100%;
    position: relative;

    tbody {
        background: inherit;
    }
}

table.au_newsevent-event td {
    padding: 5px 10px;
}

td.au_newsevent-where-text, td.au_newsevent-date-text, td.au_newsevent-from-time {
    font-weight: bold;
}