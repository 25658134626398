:focus {
  outline: none !important;
}

body.layout13 {
  background: #f5f5f5;
}

ul {
  margin-left: 0;

  ul {
    margin-top: .65rem;
  }
}

ol {
  margin-left: 2.2rem;
  li {
    margin-bottom: 1.5rem;

    li {
      margin-left: 1rem;
    }
  }
}

.button,
label {
  font-family: "AUPassataRegular";
}

ul li,
.footer ul li,
.au_shortcuts ul li,
.universe-utility ul li {
  list-style: none;
  padding-left: 1.5rem;
  margin-bottom: .65rem;
}

.main ul li:before,
.footer ul li:before,
footer ul li:before,
.au_shortcuts .last ul li:before,
.universe-utility ul li:before,
.pagenav ul li:before,
.au_alphabox ul.items li:before,
.utility-links .au_databox .content ul li:before,
.top-bar-content ul li:before,
.target-group ul li:before,
.au_news_popup ul li:before {
  font-family: "au_icons";
  content: '≥';
  font-size: .8rem;
  position: absolute;
  margin-left: -1.5rem;
  line-height: 2.5em;
  font-weight: bold;
}

.resetlist>li,
header .resetlist>li,
.header-menu .resetlist>li,
.main .resetlist>li,
header .resetlist>li:before,
.main .resetlist>li:before,
.resetlist>li:before,
.au_news_popup .resetlist>li:before {
  margin: 0;
  padding: 0;
  background-image: none;
  content: '';
  list-style-type: none;
}

.indent {
  padding-left: 1.4rem;
}

.main {
  a {
    text-decoration: underline;
  }

  h1,
  h2,
  h3,
  h4 {
    margin-top: 1em;
    margin-bottom: 0;
  }

  .aubanner a {
    text-decoration: none;
  }
}

.hero {
  //background-image: url(http://lawaudk.local/uploads/tx_gridelements/hero1.jpeg);
  background-color: transparent;
  background-size: cover;
  background-position: top center;
  color: white;
  height: 600px;

  .layout13 & {
    margin-top: -150px;
  }

  @include breakpoint(xlarge up) {
    body:not(.layout13) & {
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 2.5em;
      height: 580px;
      background-size: initial;
      background-position-y: -20px;
    }
  }

  padding-top: 250px;
  font-family: "AUPassataRegular";
  font-size: 200%;

  .row .columns {
    padding-right: 340px;

    @include breakpoint(small only) {
      padding-right: 0;
    }
  }

  a,
  a:hover {
    text-decoration: none;
  }

  p {
    line-height: 1.4;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2em;
  }

  h1 {
    font-size: 350%;
  }

  h2 {
    font-size: 300%;
  }

  h3 {
    font-size: 250%;
  }

  h4 {
    font-size: 200%;
  }

  h5 {
    font-size: 150%;
  }

  h6 {
    font-size: 125%;
  }

  @include breakpoint(medium down) {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 150%;
    }

  }

  @include breakpoint(small only) {
    padding-top: 180px !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 175%;
    }

    p {
      line-height: 1.4;
      font-size: .9em;
    }
  }
}

@include breakpoint(medium only) {
  header .header-menu .columns .menu {
    font-size: calc(1vw + 8px) !important;
  }

  header .is-dropdown-submenu-parent a {
    padding: 1rem 1vw 1rem 1vw !important;
  }

  header .is-dropdown-submenu-parent:after {
    display: none;
  }

  .left-menu {
    padding: 2rem 1rem !important;
  }
}

.expanded .row {
  max-width: $global-width;
  margin-left: auto;
  margin-right: auto;
}

.row.expanded .row .row {
  margin-left: -(map-get($grid-column-gutter, medium) / 2);
  margin-right: -(map-get($grid-column-gutter, medium) / 2);
}

header {
  font-family: "AUPassataRegular";
  position: relative;

  .department &,
  .general.neutral & {
    height: 150px;
  }

  z-index: 2;

  .section-header.row {
    height: 96px;
    position: relative;

    .columns {
      background-color: $primary-secondary-color;
      color: #fff;
    }

    @include breakpoint(medium up) {
      .department & {
        height: 50px;
      }
    }
  }

  .department & {
    height: auto;

    .header-menu .columns {
      background-color: #fff;

      @include breakpoint(medium up) {
        border-bottom: 1px solid #E4E4E4;
      }
    }

    .find,
    .search,
    .utility-links {
      position: absolute;
      right: 0;

    }

    .search {
      width: 20px;
      right: 2rem;
      top: 0;
      padding-top: .5rem;
      padding-left: 1rem;

      input {
        border: 0 !important;
      }

      border-left: 1px solid rgba(#fff, .15);

      @include breakpoint(small only) {
        padding: 0 1.6rem;
        height: 60px;
      }
    }

    .find {
      right: 40px;
      padding-top: .3rem;
      top: 0;
      border-left: 1px solid rgba(#fff, .15);

      .find-button {
        padding-right: 40px;
        font-size: 1.45rem;
      }

    }

    .utility-links {
      left: auto;
      right: 14rem;
    }

    .opensearch {
      .search {
        width: 200px;
        right: 0;
        top: .5rem;
        transition: 0.20s;
        border-left: 0;
        padding: 0 1rem;
      }

      .find,
      .utility-links,
      .language-selector {
        display: none;
      }
    }

    .find-button {
      &:after {
        font-family: "au_icons";
        content: 'f';
        font-size: 3rem;
        position: absolute;
        height: 25px;
        width: 34px;
        color: #fff;
        z-index: 100;
        display: block;
        top: 1rem;
        right: 6px;
        overflow: hidden;
      }
    }
  }

  .header-menu {
    .columns {
      background-color: #f2f2f2;
      padding: 0;
      height: auto;
      z-index: 98;

      .menu {
        font-size: 1.5rem;

        .menu {
          font-size: 100%;
        }
      }
    }
  }

  .language-selector {
    a {
      display: block;
      position: relative;
      top: .75em;
      padding-right: 1em;
    }

    @include breakpoint(small only) {
      position: absolute;
      border: 0 !important;
      display: block;

      text-align: left;
      background: none !important;
      padding-left: (map-get($grid-column-gutter, medium) / 2) !important;
    }
  }

  .seal {
    img {
      width: 75px;
      padding-top: 10px;
    }
  }

  .top-bar-content {
    position: absolute;
    left: 0;
    right: 0;
    background: #e6e6e6;
    max-width: 127.27273rem;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 0;
    z-index: 99;
    display: none;

    >.row {
      margin-left: 0;
      margin-right: 0;
    }

    h5 {
      font-size: 2rem;
    }

    @include breakpoint(small only) {
      position: static;
    }
  }
}

.opensearch {
  .au_searchform:before {
    content: '';
  }
}

@include breakpoint(medium up) {
  .general:not(.neutral) header .top-bar .menu {
    position: relative;
  }
}

.general header .top-bar .menu,
.department header .top-bar.custom .menu {

  @include breakpoint(small only) {
    display: block;
  }

  >li:hover {
    background-color: #F2F2F2;
  }

  .active>a:hover {
    background-color: #F2F2F2;
  }

  >li {
    //padding-right: 2rem !important;

    >a {
      position: relative;
      padding: 1.2rem;
      padding-right: 3rem;

      &:hover {
        color: darken($magenta-color, 10);
        text-decoration: none;
      }

      &:after {
        position: absolute;
        font-family: "au_icons";
        font-size: .9rem;
        content: '≥';
        transition: 0.70s;
        transform: rotate(90deg);
        right: 1.5rem;
        top: 1.6rem;
      }

      &:hover:after {
        transition: 0.20s;
        transform: rotate(450deg);
        color: darken($magenta-color, 10);
        text-decoration: none;
      }
    }
  }
}

body.general.neutral header,
body.department header {
  .menu a {
    padding: 1.2rem;
  }

  .header-menu:not(.open) {
    .menu {
      >li:hover {
        background-color: #e6e6e6;
      }

      li li {
        background-color: #e6e6e6;
        margin: 0;
        padding: 0;
      }

      .active>a:hover {
        background-color: #e6e6e6;
      }
    }
  }

  .is-dropdown-submenu-parent {

    //padding-right: 2rem !important;
    a {
      padding: 1.2rem;
      padding-right: 3rem;

      &:hover {
        color: darken($staff-color, 10);
        text-decoration: none;
      }
    }

    &:after {
      position: absolute;
      font-family: "au_icons";
      font-size: .9rem;
      content: '≥';
      transition: 0.70s;
      transform: rotate(90deg);
      right: 1.5rem;
      top: 1.2rem;
    }

    &:hover:after {
      transition: 0.20s;
      transform: rotate(450deg);
      color: darken($staff-color, 10);
      text-decoration: none;
    }
  }
}

.au_searchbox {
  z-index: 1;
  font-size: 1rem;
  cursor: pointer;

  & fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  & legend,
  & .au_searchbox_button {
    display: none
  }
}

.utility-links {
  position: absolute;
  left: 50%;
  font-size: 1.2rem;
  top: 1em;

  :lang(en) & {
    left: 45%;
  }

  ul.utility {
    margin: 0;
    column-count: 2;
    column-gap: .8rem;

    >li:nth-child(3) {
      display: inline-block;
    }

    :lang(en) &>li:nth-child(n+3) {
      margin-right: -70px;
    }

    >li {
      list-style: none;
      margin: 0;

      >a {
        color: $white;
        padding-bottom: 0;

        .department & {
          height: 36px;
        }

        display: block;

        &.float-right {
          height: 36.5px;
          //overflow: hidden;
        }

        &:after {
          content: "≥";
          color: white;
          font-family: au_icons;
          transform: rotate(90deg);
          display: inline-block;
          font-size: 1rem;
          margin-left: .5rem;
        }

        &:hover {
          text-decoration: none;
        }

        &:hover:after {
          transition: 0.20s;
          transform: rotate(450deg);
        }
      }
    }
  }

  .dropdown-pane {
    padding: 0;
    z-index: 99;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .utility-links {
    left: 40% !important;
  }
}

.department .utility-links ul.utility {
  column-count: 3;
}

.study-entries {
  height: 0;
  overflow: visible;
  position: relative;
  font-family: "AUPassataRegular";

  .columns div {
    background-color: $magenta-secondary-color;
    color: #fff;
    padding: 3em 2em;
    bottom: -5em;
    position: absolute;
    right: 0;
    min-width: 320px;

    h3 {
      font-size: 1.2rem;
      border-bottom: 2px solid #fff;
      padding-bottom: 1em;
    }

    a {
      display: block;
      color: #fff;

      &:hover {
        color: $magenta-color;
      }
    }

    ul {
      margin: 0;
    }

    li {
      font-size: 120%;
      list-style-type: none;
      line-height: 3em;
      position: relative;
    }

    li:hover:after {
      transition: .2s;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      color: #e2007a;
      text-align: center;
      background: #fff;
      position: absolute;
      right: 0;
      top: .75em;
      line-height: 32px;
      content: "≥";
      font-family: "au_icons";
      z-index: 2;
      font-size: 16px;
      margin-top: 0;
    }
  }

  @include breakpoint(small only) {
    height: auto;

    .columns {
      padding-left: 0;
      padding-right: 0;

      div {
        position: relative;
        bottom: auto;
        top: -2rem;
      }
    }
  }

  @media (max-width: 320px) {
    .columns div {
      min-width: 300px;
      top: 2rem;
    }
  }
}

.featured-content {
  padding-top: 10rem;

  .featured-content-header {
    padding-bottom: 2em;

    h3,
    h4 {
      font-family: "AUPassataRegular", Verdana, sans-serif;
    }

    h4 {
      float: right;
      border-bottom: 2px solid;
      margin-left: 3em;
      height: 2.5rem;
      font-size: 1.4rem;

      @include breakpoint(small only) {
        font-size: 1.2rem;
        margin-left: 1em;
        border: 0;
        padding-top: .25em;
      }

      a {
        span {
          font-family: "au_icons";
          padding-right: .5em;
          font-size: .75em;

          @include breakpoint(small only) {
            padding-right: 0;
          }
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.logo {
  position: relative;

  >a>h2 {
    font-family: "AUPassataRegular";
    font-size: 1.75rem;
    text-transform: uppercase;
    word-spacing: 1000px;
    line-height: 1em;
    margin: 1.2em;
    margin-left: 4.6em;
    width: 50%;

    @include breakpoint(medium up) {
      .department & {
        font-size: 1.1rem;
        margin-top: .8em;
        margin-left: 5rem;

        &:before {
          margin-top: .5rem;
        }
      }
    }

    &:before {
      content: "A";
      position: absolute;
      left: 2rem;
      font-family: "au_icons";
      font-size: 3.5em;
      margin-top: .75rem;
    }

  }

  >a {
    color: #fff;

    &:hover {
      text-decoration: none;
    }
  }

  .department & {
    font-size: .75em;
  }
}

.au_searchform {
  margin-top: 2em;

  @include breakpoint(medium up) {
    .department & {
      margin-top: 0;
    }
  }

  position: relative;

  &:before {
    font-family: "au_icons";
    content: "7";
    position: absolute;
    color: #fff;
    font-size: 200%;
    z-index: 1;
    top: 2px;

    @include breakpoint(small only) {
      font-size: 250%;
      top: -2px;
    }
  }

  &:focus:before {
    color: #0a0a0a;
  }

  #tx_googlequery_searchform_ss_q,
  #cludo-search-form-input {
    background: $primary-secondary-color;
    border: 0;
    border-bottom: 2px solid $primary-muted;
    text-align: right;
    color: #fff;

    &:focus {
      background-color: lighten($primary-secondary-color, 90%);
      border-bottom-color: transparent;
      color: #333;
      text-align: left;
    }
  }
}

.target-groups {
  padding: 5em;
  color: #fff;
  text-align: center;

  //margin-bottom: 5em;
  h2 {
    font-family: AUPassataRegular;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.6rem;
    margin-bottom: 3.2rem;
  }

  .button {
    background-color: darken($magenta-secondary-color, 10);
    color: #fff;
    padding: 1em 3.5em;
    font-size: 1.5rem;
    margin: .25rem;

    &.active {
      background-color: $magenta-color;
    }

  }
}

.target-group {
  background: url('https://cdn.au.dk/2016/assets/img/misc/aias.jpg') center center;
  background-size: cover;

  //margin-top: -5em;
  //margin-bottom: 5em;
  >div>.csc-default {
    display: none;
    padding-top: 5em;
    padding-bottom: 3em;
    color: #fff;

    &.active {
      display: block;
    }

    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  ul li:before {
    color: #fff;
  }

  .row {
    margin-bottom: 2em;
  }
}

footer.global {
  margin-top: 3em;

  .columns {
    background-color: $primary-secondary-color;
    color: #fff;
    padding-top: 2em;
    padding-bottom: 2em;

    h3 {
      font-size: 110%;
      font-family: "AUPassataBold";
      margin-bottom: 2rem;
      text-transform: uppercase;
    }

    .seal {
      width: 33%;
      margin-left: 2em;

      @include breakpoint(small only) {
        width: 75%;
        margin-left: 1em;
      }
    }

    a {
      color: #fff;

      &:hover {
        text-decoration: underline;
      }
    }

    li {
      margin-left: 0;
      list-style: none;

      &:before {
        color: #fff;
      }
    }

    ul {
      margin-left: 0;
    }

    .muted a {
      color: $primary-muted;

      &:nth-of-type(-n+2) {
        display: inline-block;
      }

      &:nth-of-type(n+3) {
        display: block;
      }
    }
  }
}

.footer {
  .au_padding {
    display: flex;
    width: 100%;
  }

  padding: 5rem 0;
  border-top: 1px solid #e4e4e4;
}

.featured-links {
  padding: 10em 0;
}

.section-title {

  h1,
  h2 {
    font-family: "AUPassataRegular";
    font-size: 3.6rem;
    padding: .5em 0;

    a {
      color: $primary-secondary-color;

      &:hover {
        text-decoration: none;
      }
    }

    @include breakpoint(small only) {
      font-size: 2rem;
    }
  }

  .columns {
    border-bottom: 3px solid $primary-secondary-color;

    @include breakpoint(xxlarge up) {
      padding: .5em 0;

    }
  }

  a {
    color: #fff;
  }
}

.section-department-center {
  background-color: #e6e6e6;

  .top-bar {
    display: block !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: .68181818rem 0;
      font-family: 'AUPassataRegular';

      @include breakpoint(small only) {
        font-size: 1.2rem;
      }
    }
  }
}

.breadcrumb {
  text-align: right;
  color: #636363;
  font-family: "AUPassataRegular";
  font-size: 90%;

  .columns {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  strong {
    font-family: "AUPassataRegular";
    font-weight: normal;
  }

  a {
    color: #7b7b7b;
    text-decoration: underline;
  }

  .bc-expand {
    display: none;
  }

  .collapse {
    >span:not(.breadcrumb-prefix) {
      display: none;

      &:first-of-type,
      &:nth-child(2),
      &:nth-last-of-type(2),
      &:last-of-type {
        display: inline;
      }
    }

    >a {
      display: none;

      &:first-of-type,
      &:nth-child(2),
      &:nth-last-of-type(2),
      &:last-of-type {
        display: inline;
      }
    }

    .bc-expand {
      display: inline-block;
      cursor: pointer;
    }
  }

  .breadcrumb-prefix {
    display: none;
  }

  .icon-bullet_rounded {
    font-size: 80%;
    cursor: none;
  }
}

h1.pagetitle {
  font-size: 3.6rem;
  margin: 0 0 1.6rem 0;
}

@include breakpoint(small only) {
  .related {
    padding-top: 3.2rem;
  }
}

.pagenav .au_branch:before {
  content: '≥≥';
  letter-spacing: -5px;
  font-family: "au_icons";
}

.left-menu {
  background: #f2f2f2;
  padding: 2rem;
  font-family: "AUPassataRegular";
  font-size: 1.5rem;
  margin-bottom: 2rem;

  ul {
    margin: 0;
    margin-left: 1rem;

    ul {
      margin-bottom: 1.6rem;
      margin-left: 0;
    }
  }

  >ul>li:before {
    font-size: 1rem;
    margin-left: -2rem;
  }

  li {
    list-style: none;
    margin-bottom: 1.4rem;

    &:before {
      font-size: .8rem;
    }

    li {
      font-size: 1.3rem;
      margin-left: 1rem;
      margin-bottom: 0rem;

    }

    a {
      display: block;
    }

  }

  a:hover,
  li.au_current a,
  li.au_current:before {
    color: $magenta-color;

    .department &,
    .general.neutral & {
      color: darken($staff-color, 10);
    }
  }

  li.au_current>a {
    font-family: "AuPassataBold";

    .department & {
      font-family: "AuPassataBold";
    }
  }


  li.au_current ul a {
    color: $primary-secondary-color !important;
  }

}

body {
  font-size: 1.3rem;
}

li:before,
label {
  color: #003d73;
}

a:hover {
  text-decoration: underline;
}

.dropdown-pane {
  background-color: lighten($primary-secondary-color, 5%);
  color: #fff;
}

.top-bar,
.top-bar ul {
  background-color: transparent;
  padding: 0;
}

.top-bar-content {
  line-height: 3em;

  .button.arrow {
    background: none;
    color: $primary-secondary-color;
    padding-right: 3em;
    border-width: 0 !important;

    &:after {
      color: #fff;
      background: $primary-secondary-color;
    }
  }
}

.title-bar {
  background: transparent;

  a {
    color: #000;
  }
}

@include breakpoint(small only) {
  .title-bar {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .content {
    padding-top: (map-get($grid-column-gutter, medium) / 2);
  }

  .left-menu {
    h3 {
      display: none;
    }
  }

  .au_responsive_hidden {
    display: none;
  }

  .au_responsive_only {
    display: block;
  }
}

.header-menu {
  z-index: 3;

  &.open {

    .language-selector,
    .search {
      display: none;
    }

    body:not(.department) {
      & .menu-icon::after {
        background: #fff;
        box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
      }
    }

    li {
      background: $primary-secondary-color;

      a {
        color: #fff;
      }
    }

    .is-accordion-submenu-parent>a::after {
      content: '+';
      border: 0;
      color: #9B9B9B;
      right: 2rem;
      position: absolute;
      font-size: 130%;
      font-family: "AUPassataBold";
      margin-top: -3px;

    }

    [aria-expanded="true"].is-accordion-submenu-parent>a::after {
      transform: rotate(45deg);
      transition-duration: .2s;
      color: #fff;
      ;
    }

    li {
      font-size: 1.5rem;

      a {
        border-color: rgba(#fff, .15) !important;
        border-width: 1px;
        padding: 1.5rem;

        &:hover {
          text-decoration: underline;
        }
      }

      li {
        font-size: 1.2rem;

        a {
          padding: 1rem;
          padding-left: 3rem;
        }
      }
    }
  }

  .menu-title {
    float: left;
    padding: .6em 0 .0em .4em;
  }
}

#fade {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 99;
  opacity: 0;
  display: none;

  .transparent {
    opacity: 0 !important;
  }
}

.au_contentauthor {
  border-top: 1px solid #e4e4e4;
  clear: both;
  padding: 1.2em 0;
  font-size: 90%;
  color: #636363;
  font-family: AUPassataRegular;
  text-transform: none;
  /*ændret fra uppercase*/
  padding-bottom: 2.2em;
  display: block;
  overflow: hidden;
}

div.au_contentauthor>div {
  display: inline-block;
}

.tx-lfcontactauthor-pi1 a {
  color: #636363;
}

@include breakpoint(small only) {

  // Frontpage
  .level0 {

    & .pagenav,
    & #au_mob_togglesectionnav,
    & .main>h3 {
      display: none;
    }

    & .top-bar.expanded .title-area {
      background: transparent;
    }

    & .top-bar {
      background: transparent;
    }

    & div.languageselector {
      right: 6.75rem;
    }
  }

  // Subpages
  :not(.level0) {
    & .top-bar {
      display: none;
    }

    & .sectiontitle {
      padding-bottom: .5em;
    }

    & #au_mob_togglesectionnav {
      top: 65px;
    }
  }

  .section-nav-open div.languageselector,
  div.languageselector {
    position: absolute;
    right: 3.75rem;
    top: 40px;
    display: block;
    z-index: 2;
  }

  .sectiontitle {
    padding-top: 2em;
    z-index: 2;
  }

  .main>h3 {
    //background: $bss-color;
    color: #fff;
    //padding: $column-gutter/ 2;
    margin: 0;
    padding-top: 0;
  }

  .pagenav {
    padding-top: 0;

    & ul li {
      font-size: 15px;

      &:before,
      &.au_selected_branch:before,
      &.au_branch:before,
      &.au_selected:before,
      &.au_current:before {
        margin-left: -25px;
      }
    }
  }

  #au_mob_togglesectionnav {
    top: 76px;
    position: absolute;
    right: 0;
  }

  .top-bar .toggle-topbar.menu-icon {
    margin-top: -31px;
  }

  div.search {
    z-index: 2;
    display: block;
    position: absolute;
    width: 2em;
    right: 16px;
    border: 0;

    &::after {
      font-size: 2em !important;
      top: 1.5em !important;
      right: 0 !important;
    }

    & input[type="text"] {
      background: transparent !important;
      border: 0;
      color: #515151;
      float: right;
      width: 1.5em;
      margin: 0;
      padding: 0 !important;
      box-sizing: border-box;
      box-shadow: none;
      height: 3.5rem;
      font-size: 16px;
    }
  }

  .opensearch {
    & .search {
      width: 100% !important;
      margin-right: 0 !important;
      transition: .4s ease-in-out;
      right: 0 !important;

      & input[type="text"] {
        width: 100%;
        transition: .2s ease-in-out;
        background-color: #fff !important;
        box-shadow: none;
        padding: .5em 1em !important;
      }

      &::after {
        visibility: hidden;
      }
    }

    & h2 {
      visibility: hidden;
    }
  }

}

@include breakpoint(small only) {

  .mobile-nav {
    margin-right: 1rem;
  }

  .department {
    .language-selector {
      padding-top: .5rem !important;
      right: 58px !important;
    }

    .opensearch .search {
      top: 0 !important;
    }
  }

  .header-menu {
    .utility-links {
      width: 100% !important;
      z-index: 1;
      top: 0;

      .open & {
        display: block;
      }

      position: relative !important;
      right: auto !important;
      left: auto;
      order: 3;

      ul.utility {
        column-count: 1 !important;
        display: block;

        li {
          display: block;

          a {
            display: block;
            background: #e6e6e6;
            color: $primary-secondary-color;
            padding: .5rem 1.6rem;
            float: none !important;

            &:after {
              color: $primary-secondary-color;
            }

            :not(.department) & {
              background: darken(#e6e6e6, 10%);
            }
          }
        }
      }

    }

    &:not(.open) {

      .utility-links,
      #au_section_nav_inner_list {
        display: none;
      }
    }

    /*
    :not(.level0) & #au_section_nav_inner_list,
    :not(.level0) &+.columns #au_section_nav_inner_list {
      order: 2;
      width: 100%;
      margin-left: 0;
      z-index: 2;
      margin-bottom: 0;

      li {
        margin-bottom: 0;
        padding-left: 0;

        a {
          padding: 1rem 1.5rem;
          display: block;
        }
      }
    }
    */

    body:not(.level0):not(.layout16):not(.layout17) & {
      #menu {
        display: none !important;
      }
    }
  }

  body.level0.department {
    div.section-header:not(.no-topmenu)~div #au_section_nav_inner_list {
      display: none;
    }
  }

  .left-menu {
    padding: 0;
    border-bottom: 1px solid #e6e6e6;
  }

  .top-bar {
    position: relative;
    z-index: 1;
  }

  .logo>h2,
  .logo>a>h2 {
    font-size: 1.2rem;
    margin-left: 4.6em;

    &:before {
      left: 1rem;
    }
  }

  .au_searchform {
    margin-top: 1rem;
  }

  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: transparent !important;
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: transparent;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: transparent;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: transparent;
  }

  header .section-header.row {
    height: 58px;

    .main-logo {
      object {
        height: 49px;
        margin-left: 4px;
      }

      .bss & {
        width: 55px;
      }
    }
  }

  body:not(.department) {

    div.search {
      right: 2em;

      #tx_googlequery_searchform_ss_q,
      #cludo-search-form-input {
        border-bottom: 0;
      }
    }

    .title-bar {
      padding: 0;
    }

    .mobile-nav {
      position: absolute;
      right: 0;
      top: 0;
      padding: 1.2em 1em;
      height: 60px;
      z-index: 2;
      margin-right: 0;

      .menu-icon::after {
        background: #fff;
        box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
      }

      div.search {
        right: 4em;
      }
    }

    .header-menu.open {
      >div li {
        background: #E6E6E6;
        color: #003d45;

        a {
          color: #003d45;
        }
      }

      /*
      .menu-icon::after {
        background: $primary-secondary-color;
        box-shadow: 0 7px 0 $primary-secondary-color, 0 14px 0 $primary-secondary-color;
      }
      */

      li a {
        border-color: rgba($magenta-secondary-color, .15) !important;
        border-width: 1px;
        border-bottom-style: solid;
      }

      [aria-expanded="true"].is-accordion-submenu-parent>a::after {
        color: $magenta-color;
      }
    }

    .language-selector {
      right: 8rem;
      top: .75rem;

      a {
        color: #fff;
      }
    }
  }

  body.level0 .header-menu.open div li li a {
    border: 0 !important;
    padding-left: 0;
  }
}

/* jQuery UI fix */

.ui-autocomplete {

  position: absolute;
  z-index: 101;

  .ui-state-focus {
    background-color: #f2f2f2 !important;
    background-image: none !important;
    border: none !important;
  }

  .ui-menu-item {

    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    font-size: 1.1rem;

    a {
      text-decoration: none;
      display: block;
      padding: .3em .4em;
      line-height: 1.5;
      zoom: 1;
    }

    &.more {
      font-weight: bold;
    }

  }
}

.ui-helper-hidden-accessible {
  position: absolute;
  left: -9999px;
}

.ui-menu {
  max-height: 300px;
  overflow-y: scroll;
}

.ui-autocomplete-loading {
  background: url('//cmsdesign.au.dk/design/2008/graphics/gsa/ajax-loader.gif') no-repeat scroll 95% 50%;
}

[data-dropdown-menu] ul {
  display: none;
}

.utility-links .dropdown-pane {

  @include breakpoint(small only) {
    width: 100%;
  }

  &.student {
    background-color: lighten($student-secondary-color, 5%);
  }

  width: 360px;

  >h2 {
    padding: 1rem 1rem 0 1rem;

    a {
      color: #fff;
    }
  }

  >p {
    padding: 0 1rem;
    font-size: 1.25rem;
  }

  .button {
    &.text-left {
      text-align: left;
    }

    /*
        &:not(:last-of-type) {
          border-bottom: 1px solid #fff;
        }*/

    border-top: 1px solid #fff;
    margin-bottom: 0;

    &.margin {
      border-top: none;
      margin: 1rem;
      width: auto;

      &:after {
        transition: .2s;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        color: #333;
        text-align: center;
        background: #fff;
        position: absolute;
        right: .65rem;
        bottom: .65rem;
        line-height: 2.4em;
        content: "≥";
        font-family: "au_icons";
        z-index: 1;
        font-size: 1.2rem;
      }
    }
  }
}

.dropdown-pane {
  right: 0;
  z-index: 110;
}

#utility-pane-staff {
  background-color: $staff-color;
}

#utility-pane-student {
  background-color: $student-color;
}

#utility-pane-phd {

  .bg-dark-phd,
  .button.bg-phd:hover {
    background-color: #002546 !important;
  }
}

@include breakpoint(medium only) {
  .medium-only-portrait-3 {
    @include breakpoint(portrait) {
      @include flex-grid-column(3);
    }
  }
}

.medium-only-portrait-4 {
  @include breakpoint(medium only) {
    @include breakpoint(portrait) {
      @include flex-grid-column(4);
    }
  }
}

@include breakpoint(medium only) {
  .medium-only-portrait-6 {
    @include breakpoint(portrait) {
      @include flex-grid-column(6);
    }
  }
}

@include breakpoint(medium only) {
  .medium-only-portrait-8 {
    @include breakpoint(portrait) {
      @include flex-grid-column(8);
    }
  }
}

@include breakpoint(medium only) {
  .medium-only-portrait-12 {
    @include breakpoint(portrait) {
      @include flex-grid-column(12);
    }
  }
}

.department {
  .language-selector {
    position: absolute;
    right: 126px;
    top: 0.28em;
    z-index: 2;
    font-size: 125%;

    a {
      color: #fff;
    }
  }

  @include breakpoint(medium up) {
    .utility-links {
      right: 20rem !important;
    }
  }
}


.neutral {
  .language-selector {
    position: absolute;
    right: 215px;
    top: 0.75em;
    z-index: 1;
    font-size: 125%;

    @include breakpoint(small only) {
      right: 4rem !important;
      top: 0.5em !important;
      font-size: 100%;
    }

    a {
      color: #fff;
    }
  }
}

.au_social_sharing a {
  text-decoration: none;
}

@include breakpoint(xxlarge up) {
  .main {
    padding-right: 0;

    .layout16 &,
    .layout17 & {
      padding-left: 0;

      &.content .row {
        max-width: 130rem;
      }
    }
  }
}

.main-logo {
  display: block;
  width: 252px;
  min-height: 42px;
  z-index: 1003;
  overflow: hidden;
  margin: 4px 0 0 -10px;

  object {
    height: 42px;
    pointer-events: none;
  }
}

#tx_googlequery_searchform_ss_q.loading {
  background-position: 95% !important;
}

@include breakpoint(medium up) {
  .general {
    .main-logo {
      margin: 15px 0 0 0px;

      object {
        height: 58px;
      }
    }
  }

  .au_responsive_only {
    display: none;
  }
}

@media (max-width: 320px) {
  header .section-header.row .main-logo {
    .bss & {
      width: 42px;
    }

    object {
      height: 40px;
      margin-left: 0;
      margin-top: 5px;
    }
  }
}

.neutral-container {
  clear: both;

  div[class*="bg-"]:not(.aubanner) {
    padding: 1rem;
    margin-bottom: 1rem;
  }

  div[class*="bg-light"],
  div[class*="bg-dark"] {
    .au_collapsible {
      padding: 0;
      background-color: transparent;
    }
  }

  div[class*="bg-dark"] {
    color: #fff;

    li:before {
      color: #fff;
    }

    a {
      color: #fff;
    }
  }

  .csc-firstHeader {
    margin-top: 0;
  }
}

.neutral-container.expanded {
  margin: 1rem 0 1rem 0;
}

.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

.general {
  .menu {

    .active>a,
    .active>a:hover {
      color: $magenta-color;
      background-color: transparent;
    }
  }

}

.urgency>div {
  a {
    color: #000;
  }

  background-color: #F8E71C;
  padding: 1.5rem;
  position: relative;

  div.small-12 {
    padding-left: 6rem;
    padding-right: 50%;

    p {
      margin-bottom: 0;
    }

    &:before {
      font-family: "au_icons";
      content: 'i';
      font-size: 3rem;
      position: absolute;
      margin-top: 0;
      margin-left: -4.5rem;
      line-height: 2em;
      color: #f00;
      z-index: 2;
    }

  }

  @include breakpoint(small only) {
    z-index: 2;

    div.small-12 {
      padding-left: 7rem;
      padding-right: 0;

      &:before {
        font-size: 5rem;
        margin-top: 0;
        margin-left: -6.5rem;
        line-height: 5rem;
      }
    }
  }
}

.page-id {
  font-family: Georgia;
  color: #06315a;
  width: 100%;
  text-align: right;
  display: block;
  padding-right: 1em;
  margin-top: -2em;
  font-size: 80%;

  &:hover {
    color: #fff;
  }

  &:before {
    content: "page id: ";
  }
}

/* Responsive video fra YouTube */
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  /*skal bare være mellem 50 og 60% */
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Historic content */
.au_historic.row.historic {
  -webkit-clip-path: polygon(3% 0, 7% 1%, 11% 0, 16% 2%, 20% 0, 23% 2%, 28% 2%, 32% 1%, 35% 1%, 39% 3%, 41% 1%, 45% 0, 47% 2%, 50% 2%, 53% 0, 58% 2%, 60% 2%, 63% 1%, 65% 0, 67% 2%, 69% 2%, 73% 1%, 76% 1%, 79% 0, 82% 1%, 85% 0, 87% 1%, 89% 0, 92% 1%, 96% 0, 98% 3%, 99% 3%, 99% 6%, 100% 11%, 98% 15%, 100% 21%, 99% 28%, 100% 32%, 99% 35%, 99% 40%, 100% 43%, 99% 48%, 100% 53%, 100% 57%, 99% 60%, 100% 64%, 100% 68%, 99% 72%, 100% 75%, 100% 79%, 99% 83%, 100% 86%, 100% 90%, 99% 94%, 99% 98%, 95% 99%, 92% 99%, 89% 100%, 86% 99%, 83% 100%, 77% 99%, 72% 100%, 66% 98%, 62% 100%, 59% 99%, 54% 99%, 49% 100%, 46% 98%, 43% 100%, 40% 98%, 38% 100%, 35% 99%, 31% 100%, 28% 99%, 25% 99%, 22% 100%, 19% 99%, 16% 100%, 13% 99%, 10% 99%, 7% 100%, 4% 99%, 2% 97%, 1% 97%, 0 94%, 1% 89%, 0 84%, 1% 81%, 0 76%, 0 71%, 1% 66%, 0 64%, 0 61%, 0 59%, 1% 54%, 0 49%, 1% 45%, 0 40%, 1% 37%, 0 34%, 1% 29%, 0 23%, 2% 20%, 1% 17%, 1% 13%, 0 10%, 1% 6%, 1% 3%);
  clip-path: polygon(3% 0, 7% 1%, 11% 0, 16% 2%, 20% 0, 23% 2%, 28% 2%, 32% 1%, 35% 1%, 39% 3%, 41% 1%, 45% 0, 47% 2%, 50% 2%, 53% 0, 58% 2%, 60% 2%, 63% 1%, 65% 0, 67% 2%, 69% 2%, 73% 1%, 76% 1%, 79% 0, 82% 1%, 85% 0, 87% 1%, 89% 0, 92% 1%, 96% 0, 98% 3%, 99% 3%, 99% 6%, 100% 11%, 98% 15%, 100% 21%, 99% 28%, 100% 32%, 99% 35%, 99% 40%, 100% 43%, 99% 48%, 100% 53%, 100% 57%, 99% 60%, 100% 64%, 100% 68%, 99% 72%, 100% 75%, 100% 79%, 99% 83%, 100% 86%, 100% 90%, 99% 94%, 99% 98%, 95% 99%, 92% 99%, 89% 100%, 86% 99%, 83% 100%, 77% 99%, 72% 100%, 66% 98%, 62% 100%, 59% 99%, 54% 99%, 49% 100%, 46% 98%, 43% 100%, 40% 98%, 38% 100%, 35% 99%, 31% 100%, 28% 99%, 25% 99%, 22% 100%, 19% 99%, 16% 100%, 13% 99%, 10% 99%, 7% 100%, 4% 99%, 2% 97%, 1% 97%, 0 94%, 1% 89%, 0 84%, 1% 81%, 0 76%, 0 71%, 1% 66%, 0 64%, 0 61%, 0 59%, 1% 54%, 0 49%, 1% 45%, 0 40%, 1% 37%, 0 34%, 1% 29%, 0 23%, 2% 20%, 1% 17%, 1% 13%, 0 10%, 1% 6%, 1% 3%);
  background-color: beige;
  padding: 5rem 1rem;
  margin-left: 0;
  margin-bottom: 1rem;
  position: relative;
}


.au_historic.row.historic::before {
  content: "Historical section";
  display: inline-block;
  padding: 0 1rem;
  background-color: #fafad2;
  border: 1px solid #000;
  text-transform: full-width;
  margin: 4rem 2rem auto;
  right: 0;
  top: 0px;
  position: absolute;
}

html:lang(da) .au_historic.row.historic::before {
  content: "Historisk afsnit";
}

/* Faktaboks */
.au_box_1 {
  background: none repeat scroll 0 0 #f1f7fa;
  border-bottom: 3px solid $primary-color;
  border-top: 1px solid $primary-color;
  margin-bottom: 2rem;
  padding: 1rem;
  overflow: auto;
  float: left;
  width: 200px;
  margin-right: 1em;

  &.right {
    float: right;
    margin-left: 1em;
    margin-right: 0;
  }

  &.center {
    float: none;
    width: auto;
    margin: auto;
  }
}

/*Spinner fra au-news gøres global*/
.spinner {
  width: 40px;
  height: 40px;
  margin: 60px auto;
  background-color: #333;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0)
  }

  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

.rendering .prefix {
  display: inline;
  border: none;
  background: inherit;
  font: inherit;
}

@include breakpoint(small only) {

  .header-menu.open .responsive-pagenav,
  .universe .responsive-pagenav {
    order: 2;
    width: 100%;
    margin-left: 0;
    z-index: 2;
    margin-bottom: 0;

    ul {
      margin: 0;
    }

    li {
      padding-left: 3rem !important;
      margin-bottom: 0;

      &::before {
        font-family: au_icons;
        content: "≥";
        font-size: 1rem;
        position: absolute;
        margin-left: -2rem;
        margin-top: 1rem;
        line-height: 2.5em;
        font-weight: 700;
        color: #fff;
      }

      a {
        display: block;
        padding: 1rem 1.5rem 1rem 0;
      }

      &.lvl2,
      &.lv2 {

        /* OMG */
        &::before {
          font-size: .8rem;
        }
      }

      &.au_branch {
        &::before {
          content: "≥≥";
          letter-spacing: -5px;
        }
      }

      &.au_selected_branch {}

      &.au_current {
        font-weight: bold;
      }
    }
  }
}