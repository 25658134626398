//$theme-color: #678864;
//$header-font-family-url: 'https://fonts.googleapis.com/css?family=Oswald';
//$header-font-family-name: 'Oswald';
//$header-background-color: #575757;
//$header-foreground-color: #fff;
//$header-menu-background-color: #eaf2e5;
//$header-menu-foreground-color: $theme-color;
//$body-font-family-url: 'https://fonts.googleapis.com/css?family=Rubik';
//$body-font-family-name: Rubik;

body.general.neutral {
  .neutral-container {
    margin-bottom: 2em;
  }

  @if variable-exists(body-font-family-name) {
    @if variable-exists(body-font-family-url) {
      @import url($body-font-family-url);
    }
    font-family: $body-font-family-name;
  }

  .left-menu li.au_current {
    > a, ul a {
      font-family: inherit;
      color: inherit !important;
    }
  }

  header {

    .top-bar .menu > li > a:after {
      display: none;
    }

    .section-header.row .columns {
      @if variable-exists(header-background-color) {
        background-color: $header-background-color;
        color: $header-background-color;
      }
      @if variable-exists(theme-color) {
        a {
          color: $theme-color;
        }
      }
    }

    .header-menu {
      .columns {
        @if variable-exists(header-menu-background-color) {
          background-color: $header-menu-background-color;
        }

      }
      @if variable-exists(header-menu-foreground-color) {
        a {
          color: $header-menu-foreground-color;
        }
      }

    }

    .main-logo {
      width: 300px;
    }
    .au_searchform #tx_googlequery_searchform_ss_q {
      background: #e9e9e9;
      border-bottom: 0;
      color: #000;
      box-shadow: none;
    }
    .au_searchform:before {
      @if variable-exists(theme-color) {
        color: $theme-color;
      }
    }
    .header-menu.open li {
      background: #e6e6e6;
    }

  }
  @if variable-exists(header-background-color) {
    footer.global .columns {
      background-color: $header-background-color;
    }
  }

  .au_searchform:before {
    left: .5em;
  }
  @if variable-exists(theme-color) {
    // Menus - hover and active color
    .menu {
      .is-dropdown-submenu-parent:hover:after,
      .is-dropdown-submenu-parent a:hover,
      .is-dropdown-submenu-parent:after,
      .active > a,
      .active > a:hover {
        color: $theme-color;
      }
      .is-submenu-item a:after {
        display: none;
      }
    }
    // General link color
    a:not(.button), li:before {
      color: $theme-color;
    }

    .left-menu {
      a {
        color: #333;
      }
      a:hover,
      li.au_current > a,
      li.au_current:before {
        color: $theme-color !important;
      }
    }

    // Collapsible icon
    .csc-frame.au_collapsible > .csc-header:after, .csc-frame.au_collapsed > .csc-header:after, .column .au_collapsible .csc-header h2, .columns .au_collapsible .csc-header h2 {
      color: $theme-color;
    }
  }

  //Custom font
  @if variable-exists(header-font-family-url) {

    @import url($header-font-family-url);

    h1, h2, h3, header, .au_focus, .au_focus h1 strong, .left-menu, .button, label {
      font-family: $header-font-family-name;

    }
  }
}

