body.mod1 {
  .main-logo {
    margin: 8px 0 0;
    object {
      height: 46px;
    }
  }
  header {
    .section-header.row {
      height: 46px;
      position: relative;
    }
    .seal img {
      width: 36px;
      padding-top: 5px;
      float: right;
    }
  }

  .au_searchform {
    margin-top: 7px;
    #tx_googlequery_searchform_ss_q {
      background: #224869;
      border: 0;
      text-align: right;
      color: #fff;
      height: 32px;
      &:focus {
        background: #fff;
        color: #333;
        text-align: left;
      }
    }
    &:before {
      top: 0;
      left: .5em;
    }
  }
  @include breakpoint(large up) {
    .au_searchbox {
      position: relative;
      left: 63px;
    }
  }
}