#inputSearch {
  display: inline !important;
  font-size: 1em!important;
  width: 200px!important;
  padding: .72727rem !important;
}
#inputSearchAttribute {
  display: inline-block !important;
  font-size: 1em !important;
  width: 165px !important;
  margin: 0 1em !important;
  padding: .72727rem !important;
  padding-right: 2.18182rem !important;
}