#au_maps_route_from_container, #au_maps_route_to_container, #au_maps_building_content, #au_maps_direct_link
{
    display: none;
}

#au_maps_route_directionspanel
{
    margin-bottom: 1rem;
}

#au_maps_search_route .au_collapsible {
    background-color: #f9f9f9;
    .csc-header h1 {
        background-color: transparent !important;
    }
}

#au_maps_container {

    margin-bottom: 2rem;

    > .column > div {
        padding-top: 1rem;
        border-top: 1px solid #ccc;
        margin-bottom: 1rem;
    }
}

#au_maps_overlay_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .button {
        text-decoration: none;
        width: 49%;
        margin-bottom: .65rem;
        cursor: pointer;
    }
}

.au_maps_people_table .header, .au_maps_people_table th
{
    font-weight: bold;
    cursor: pointer;
}

.au_maps_people_table .alignright
{
    text-align: right;
}

.dataTables_paginate
{
    margin: auto;
    text-align: center !important;
    width: 200px;
}

.dataTables_paginate .paginate_button, .dataTables_paginate .paginate_active
{
    margin: 0px 3px;
    cursor: pointer;
}

.dataTables_paginate .paginate_active
{
    text-decoration: underline;
}