/*########################*/
/* --- GSA SEARCH --- */
/*########################*/
.searchpage .columns.search {display:none;}

div.au_content_results ul li.g {
  background: none;
  line-height: 1.3em;
  list-style: none outside none;
  margin-left: 0 !important;
  padding-left: 0px;
  margin-bottom: 1.5em;
}

div.au_content_results li.g a span {
  font-size: 110%;
}

div.au_content_results ul li span.url {
  color: #666;
  font-size: 90%;
}

div.au_content_results ul li span.title {
  font-size: 110%;
}

div.au_gsa_keymatches {
  margin: 2em 0;
  padding: 0.75em;
  background: #e9e9e9;
}

div.au_gsa_keymatches p {
  font-weight: bold;
  float: right;
}

div.au_gsa_keymatches ul {
  padding: 0;
  margin: 1em;
}

div.au_gsa_meta {
  font-size: 90%;
  color: #333;
}

ul li.g table {
  border:none;
}

ul li.g table tr td {
  font-size: 1rem;
}

/* = TABS = */
ul.tabs {
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/tabs_bg2.png") repeat-x scroll 0 -138px transparent;
  font-size: 0;
  height: 23px;
  list-style: none outside none;
  margin: 10px 0 1em;
  padding: 0;
}

ul.tabs li {
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  vertical-align: top;
  background: none;
  padding-left: 0 !important;
  float: right;
}

ul.tabs li:before, ul li.g:before {
  content: "" !important;
}

ul.tabs li.gsa-tab-last {
  float: right;
  margin-right: 0;
}

ul.tabs li.gsa-tab-first {
  float: left;
  margin-right: 0;
}

ul.tabs li a {
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/tabs_bg2.png") no-repeat scroll 0 0 transparent;
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  height: 23px;
  line-height: 22px;
  text-decoration: none;
  vertical-align: top;
  border: none !important;
}

ul.tabs li a:hover {
  border: none;
}

ul.tabs a span {
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/tabs_bg2.png") no-repeat scroll right -23px transparent;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  padding: 0 8px;
}

ul.tabs a:hover {
  background-position: 0 -46px;
  text-decoration: none !important;
}

ul.tabs a:hover span {
  background-position: right -69px;
}

ul.tabs li.selected a {
  background-position: 0 -92px;
  color: #002546;
  background: #eee;
}

ul.tabs li.selected a span {
  background-position: right -115px;
}

div.tab_content {
  border-top: none;
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/results_bg.png") top left no-repeat;
  min-height: 300px;
}

div.tab_content div:first-child {
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/results_bg.png") top right no-repeat;
}

div.tab_content div:first-child div {
  background: none;
}

div.au_content_results {
  padding: 2em;
}

ul.tabs li.gsa-tab-label span {
  font-size: 11px;
  line-height: 22px;
  padding-right: 0.5em;
}

/* = AU GSA SEARCH FORM = */
div#au_searchbox.au_gsa input#tx_googlequery_searchform_ss_q {
  width: 80px;
}

div.au_gsa fieldset {
  border: none;
  padding: 0;
}

div#au_searchbox.au_gsa {
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/au_gsa_search_bg.gif") no-repeat scroll 0 0 transparent;
  height: 25px;
  padding-bottom: 2em;
  left: 0px;
  margin-left: 0px;
  width: 406px;
}

div#au_searchbox.au_gsa input#tx_googlequery_searchform_ss_q {
  background: none repeat scroll 0 0 transparent;
  border: 0 none;
  color: #515151;
  float: left;
  margin: 2px 0 0 20px;
  padding: 3px 0 2px 4px;
  width: 290px;
  box-shadow: none;
  height:2rem;
}

div#au_searchbox.au_gsa input.au_searchbox_button {
  cursor: pointer;
  float: right;
  margin: 3px 4px 0 0;
}

div#au_searchbox.au_gsa legend {
  display: none;
}

div#au_searchbox.au_gsa p {
  float: right;
}

/* = MISC = */
div.gsa_sorting {
  float: right;
  clear: right;
}

div.au_gsa_suggestions {
  margin-bottom: 1em;
  padding-left: 25px;
}

div.au_gsa_suggestions span {
  color: #cc0000;
}

p.gsa_results_count {
  float: right;
}

div.au_gsa_relatedqueries {
  padding-left: 2em;
}

div#au_searchbox.au_gsa input#tx_googlequery_searchform_ss_q.loading {
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/ajax-loader.gif") no-repeat scroll 95% 50%;
}

/* = PAGEBROWSER = */
.au_browse {
  clear: left;
  font-size: 0.95em;
  text-align: center;
}

.au_browse p.results_text {
  text-align: left;
  text-transform: uppercase;
}

.au_browse a, .au_browse span {
  background: #FFFFFF none repeat scroll 0 0;
  border: 1px solid #FFFFFF;
  display: block;
  float: left;
  margin-right: 0.2em;
  padding: 2px 0.4em;
}

.au_browse li.current a {
  background: #666 none repeat scroll 0 0;
  border: 1px solid #666;
  color: #FFF !important;
  font-weight: bold;
}

.au_browse a, .au_browse a:visited {
  border: 1px solid #ccc !important;
  text-decoration: none;
  color: #ccc;
}

.au_browse a:hover {
  border-color: #666;
  color: #666;
}

.au_browse li {
  margin-bottom: 0;
  padding-left: 0;
}

.au_browse li.next a, .au_browse li.last a {
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/pb_next.png") center right no-repeat;
  color: #fff;
  border: none !important;
  font-weight: bold;
  padding: 3px 0.8em;
  padding-right: 1.3em;
}

.au_browse li.previous a, .au_browse li.first a {
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/pb_previous.png") center left no-repeat;
  color: #fff !important;
  border: none;
  font-weight: bold;
  padding: 3px 0.8em;
  padding-left: 1.3em;
}

.au_browse li.previous a:hover, .au_browse li.next a:hover, .au_browse li.first a:hover, .au_browse li.last a:hover {
  color: #fff !important;
}

.au_browse ul, .au_browse ul li {
  padding: 0;
  margin-left: 0;
}

.au_floatright {
  float: right !important;
}

.au_floatleft {
  float: left !important;
}

ul.au_browse li:before {
  content: "" !important;
}

/* = SORTING WIDGET = */
.au_gsa_sorting {
  clear: right;
  width: 220px;
  height: 24px;
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/au_gsa_sort_bg.gif") 83px -48px no-repeat !important;
  position: relative;
  float: right;
}

.au_gsa_sorting a, .au_gsa_sorting a:hover {
  border: none !important;
}

.date_selected {
  background-position: 83px -24px !important;
}

.date_selected {
  background-position: 83px -24px !important;
}

.date_selected .au_gsa_sorting_relevance a[href]:link, .date_selected .au_gsa_sorting_relevance a[href]:visited {
  color: #999;
}

.date_selected .au_gsa_sorting_relevance a[href]:hover {
  color: #333 !important;
}

.date_selected .au_gsa_sorting_date a[href]:link, .date_selected .au_gsa_sorting_date a[href]:visited {
  color: #fff;
}

.date_selected .au_gsa_sorting_date a[href]:hover {
  color: #fff !important;
}

.etad {
  background-position: 83px 0px !important;
}

.au_gsa_sorting a[href]:link, .au_gsa_sorting a[href]:visited {
  text-decoration: none;
  color: #fff;
}

.au_gsa_sorting a[href]:hover {
  text-decoration: underline;
  color: #fff !important;
}

.au_gsa_sorting_label {
  position: absolute;
  top: 3px;
}

.au_gsa_sorting_relevance {
  position: absolute;
  top: 1px;
  left: 94px;
}

.au_gsa_sorting_date {
  position: absolute;
  top: 1px;
  left: 161px;
}

.au_gsa_sorting_date a[href]:link, .au_gsa_sorting_date a[href]:visited {
  color: #999;
}

.au_gsa_sorting_date a[href]:hover {
  color: #333 !important;
}

/*MAIN PAGE SEARCH*/
div.au_content_results ul li.g {
  clear: both;
}
.tab_content .au_content_results p {
  clear: both;
}
.main .au_gsa_search_select li:before {
  content: initial;
}
.main .au_gsa_search_select a {
  text-decoration: none;
}
.au_gsa_search_form input[type=text] {
  float:left;
  width: calc(100% - 50px)!important;
}
.au_gsa_search_form input[type=image] {
  margin-right: 10px;
}
#au_searchbox.au_gsa_search_form {
  height:50px;
}
.au_gsa_search_form label {
  padding:10px 0;
}
/*MAIN PAGE SEARCH END*/

/*FACULTIES SEARCH*/
#searchtips {
  display: none;
  background: #f2f2f2;
  padding: 1em;
  width: 100%;
  z-index: 1;
}
.au_browse a {
  line-height:20px;
}
ul.tabs a span {
  font-size:12px;
  background:none;
}
ul.tabs {
  border:none;
}
ul li.g table tr td {
  font-size: inherit;
  padding: .72727rem 0;
}
.au_gsa_meta {
  font-style: italic;
}
ul.tabs li a {
  border: 1px solid #cfcfcf!important;
  border-radius: 5px 5px 0 0;
  background:none;
  transition:ease-in-out 0.2s;
}
ul.tabs li a:hover,
li.gsa-tab.selected {
  background:#002546;
  border:1px solid #002546!important;
  border-bottom:1px solid #cfcfcf!important;
  transition:ease-in-out 0.2s;
}
ul.tabs li.selected a {
  background: #002546;
  border-radius: 5px 5px 0 0;
  border: 1px solid #002546!important;
  color: #fff;
}
.au_browse li.current a {
  background: #002546;
  border:1px solid #002546!important;
}
li.next,
li.previous {
  float: left;
  background: #002546;
  color: #FFF;
  position: relative;
  line-height: 20px;
  font-size:inherit;
}
li.previous {
  margin-right:5px;
}
li.next:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 15px solid #002546;
  position: absolute;
  top: 0;
  right: -15px;
}
.au_browse li.next a,
.au_browse li.previous a  {
  background: none;
  padding: 3px 12px;
  text-decoration:none;
  border:none!important;
}
li.previous:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-right: 15px solid #002546;
  position: absolute;
  top: 0;
  left: 2px;
}
ul.tabs .gsa-tab-first a span {
  background: none;
}
/*FACULTIES SEARCH - END*/
