.au-pie-container {

    clear: both;
    margin-bottom: 2em;
    overflow: auto;

    > div {
        float: left !important;
    }

    .pie-legend {

        list-style: none;
        float: right;
        margin: 0 !important;

        li {

            display: block;
            padding-left: 30px !important;
            position: relative;
            margin-bottom: 4px;
            border-radius: 5px;
            padding: 2px 8px 2px 28px;
            font-size: 14px;
            cursor: default;
            -webkit-transition: background-color 200ms ease-in-out;
            -moz-transition: background-color 200ms ease-in-out;
            -o-transition: background-color 200ms ease-in-out;
            transition: background-color 200ms ease-in-out;

            &:hover {
                background-color: #fafafa;
            }

            span {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 20px;
                height: 100%;
            }
        }

    }
}