@font-face {
  font-family: 'font-awesome';
  src: url('https://fonts.au.dk/fonts/fa-pro-light-300.otf');
  font-weight: 300;
  font-style: normal;
}

$filter-overlay-transition-time: 170ms;
$filter-overlay-transition-rotate: .5;
$filter-overlay-transition-scale: .85;
$filter-overlay-transistion-out: cubic-bezier(0.55,
  0.055,
  0.675,
  0.19);
$filter-overlay-transistion-in: cubic-bezier(0.215,
  0.61,
  0.355,
  1);
$filter-button-transition-time: 200ms;
$filter-button-transition-delay: $filter-button-transition-time / 4;
$filter-button-transition-rotate: -100deg;
$filter-button-transition-scale: .3;
$filter-button-transition-out: cubic-bezier(0.55,
  0.055,
  0.675,
  0.19);
$filter-button-transition-in: cubic-bezier(0.215,
  0.61,
  0.355,
  1);
$filter-button-transition-background: cubic-bezier(0.645,
  0.045,
  0.355,
  1);
$list-transition-time: 100ms;
$list-transition-delay: $list-transition-time / 3;
$list-transition-rotate: .5;
$list-transition-scale: .85;
$list-transition-num: 5;
$list-transition-in: cubic-bezier(0.215,
  0.61,
  0.355,
  1);
$staff-secondary-color: rgb(0,
  62,
  92);
$darkred-color: rgb(91,
  12,
  12);
$magenta-color: rgb(226,
  0,
  122);
$staff-color: rgb(55,
  160,
  203);

.no-wrap {
  white-space: nowrap;
}

.avoid-wrap {
  display: inline-block;
}

/* Blå kasse */

.au-vacancy-filter-container {
  background-color: $staff-secondary-color;
  background-image: url('https://cdn.au.dk/apps/react/vacancies/latest/jobs-wide.svg');
  background-size: cover;
  overflow: visible;
  color: #fff;
  font-family: 'AUPassataRegular';
  font-size: 1.5rem;
  position: relative;
  padding-top: 2rem;

  &.empty {
    background-image: url('https://cdn.au.dk/apps/react/vacancies/latest/empty-state-wide.svg');
    overflow: auto;
    margin-bottom: 1.3636364rem;
  }

  strong {
    font-family: 'AUPassataBold';
    font-weight: normal;
  }

  >h1 {
    margin: 0 1rem 2rem 2rem !important;
    font-family: inherit;
    font-size: 3rem;
    font-weight: normal;
    line-height: 1.1;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  /* Række med hvide streger */
  .au-vacancy-vip-tap {
    position: relative;
    font-size: 1.81818181rem;
    border-top: 1px solid rgba(#fff, .3);
    border-bottom: 1px solid rgba(#fff, .3);
    padding: .65rem 2rem .65rem 1rem;
    display: flex;
    flex-direction: row;

    >div:not(.mobile) {
      padding-right: 1.2rem;
    }

    /* vip/tap filterknapper */
    >div>button {
      padding: 1rem 1.2rem 1rem 4rem;
      position: relative;

      &:hover {
        color: rgba(#fff, .75);
      }

      &::after {
        text-align: center;
        width: 36px;
        height: 36px;
        top: 3px;
        left: 3px !important;
        line-height: 36px;
        font-size: 26px;
      }

      &:first-child {
        display: none;

        &::before {
          content: '\f0ca';
        }
      }
    }
  }

  /* PhD link */
  a.button.phd {
    margin: 0 0 0 auto;
    text-transform: uppercase;
    font-family: 'AUPassataBold';
    padding-top: 14px;
    align-self: flex-start;
    white-space: nowrap;

    &:hover {
      background-color: darken($staff-color, 10%) !important;
    }

    &::after {
      font-family: 'au_icons';
      content: '\3e';
      margin-left: .7rem;
    }

    &.empty,
    &.empty::after {
      background-color: transparent !important;
    }
  }

  /* Filterknapper */
  .au-vacancy-filter {
    padding: .5rem 2rem 0 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;

    /* Tilføj filter */
    >span {
      display: inline-block;
      margin-right: 1rem;
      padding: 0.64rem 0;
      line-height: 1;
    }

    >div:not(.mobile) {
      position: relative;
      margin-bottom: .5rem;

      >button {
        padding: 0.64rem 1rem;
        margin-right: 1rem;
        border-radius: 16px;

        &::after {
          width: 24px;
          height: 24px;
          top: 3px;
          left: 3px;
          line-height: 24px;
          font-size: 17px;
          text-align: center;
        }

        &.selected {
          padding-left: 3rem;
          padding-right: 1.2rem;
        }
      }
    }

    .au-vacancy-filter-areas .au-vacancy-filter-list,
    >div.mobile.au-vacancy-filter-list {
      /* Fagområde */
      width: 340px;

      li.area {
        button {
          span:last-child {
            font-size: 1.2rem;
            color: rgba(#fff, .75);
          }

          &.item-selected {
            span:last-child {
              color: $darkred-color;
            }
          }
        }
      }
    }
  }

  /* Mørkerøde filterkasser */
  .au-vacancy-filter-list {
    position: absolute;
    top: .64rem;
    left: 1rem;
    z-index: 20;
    font-size: 1.5rem;
    background-color: $darkred-color;
    width: 300px;
    padding-bottom: .5rem;
    visibility: hidden;
    opacity: 0;
    transform: rotate3d($filter-overlay-transition-rotate, 0, $filter-overlay-transition-rotate * -.1, 90deg) scale($filter-overlay-transition-scale);
    transform-origin: 1.14rem 1rem;
    transition: all $filter-overlay-transition-time $filter-overlay-transistion-out;

    &.mobile {
      display: none;
    }

    &.visible {
      visibility: visible;
      opacity: 1;
      transform: rotate3d(0, 0, 0, 0) scale(1);
      transition: all $filter-overlay-transition-time $filter-overlay-transistion-in;
    }

    >span {
      display: block;
      margin: 1rem;
      font-size: 1.2rem;
      color: rgba(#fff, .75);
      position: relative;

      &::before {
        display: inline-block;
        position: absolute;
        content: '';
        left: 0;
        bottom: -6px;
        border-bottom: 2px solid rgba(#fff, .55);
        width: 20px;
      }
    }

    /* Luk-kryds i mørkerødt filter */
    >button:first-of-type {
      position: absolute;
      right: .65rem;
      top: .65rem;
      font-family: 'au_icons';
      font-size: 2rem;
      color: rgba(#fff, .75);
    }

    a.button {
      background-color: $magenta-color !important;
      margin: 1rem 0 1rem 1rem;

      &:hover {
        background-color: darken($magenta-color, 10%) !important;
      }
    }

    ul {
      margin: 0;

      button {
        margin-right: 0;
        padding: 1rem;
        display: block;
        width: 100%;

        &:hover {
          color: rgba(#fff, .75);
        }

        &.item-selected {
          color: $darkred-color;
          background-color: #fff;
          border-radius: 0;
        }
      }
    }
  }

  button {
    font-family: inherit;
    color: inherit;
    cursor: pointer;
    text-align: left;
  }

  /* Filterknapper - begge størrelser */
  button.filter {
    background-color: transparent;
    border-radius: 21px;
    transition: background ($filter-button-transition-time - $filter-button-transition-delay) $filter-button-transition-delay $filter-button-transition-background;

    &:hover {
      color: rgba(#fff, .75);
    }

    &::before,
    &::after {
      display: inline-block;
      font-family: 'font-awesome';
      font-weight: 300;
      position: absolute;
      border-radius: 50%;
    }

    &::before {
      content: '';
      left: 1rem;
    }

    &::after {
      content: '\f00d' !important;
      /* font-awesome X */
      background-color: $staff-secondary-color;
      color: #fff;
      opacity: 0;
      transform: rotate($filter-button-transition-rotate) scale($filter-button-transition-scale);
      transition: transform ($filter-button-transition-time - $filter-button-transition-delay) $filter-button-transition-out, opacity ($filter-button-transition-time - $filter-button-transition-delay) $filter-button-transition-out;
    }

    &.selected {
      background-color: #fff;
      color: $staff-secondary-color;
      transition: background $filter-button-transition-time $filter-button-transition-background;

      &:hover {
        color: lighten($staff-secondary-color, 9%);

        &::after {
          background-color: lighten($staff-secondary-color, 6%);
        }
      }

      &::after {
        opacity: 1;
        transform: rotate(0deg) scale(1);
        transition: transform ($filter-button-transition-time - $filter-button-transition-delay) $filter-button-transition-delay $filter-button-transition-in, opacity ($filter-button-transition-time - $filter-button-transition-delay) $filter-button-transition-delay $filter-button-transition-in;
      }
    }

    /* Mikroskop */
    &.vip::before {
      content: '\f610';
    }

    /* Værktøjskasse */
    &.tap::before {
      content: '\f0b1';
    }
  }

  a.button.empty {
    text-transform: uppercase;
    margin-left: 2rem;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    background-color: $staff-color;
  }
}

.au-vacancies-no-results {
  font: 1.81818182rem 'AUPassataRegular';
  padding: 6rem 0 4rem 0;
  max-width: 550px;
  margin: auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .meh-with-cap {
    display: inherit;
    flex: 0 0 auto;
    flex-direction: column;

    img {
      flex: 1 0 auto;
      margin-right: 2rem;
      min-width: 10rem;
    }
  }

  .no-results {
    flex: 1 0 300px;

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.au-vacancies-list {
  margin: 2rem 0;
  font-family: 'AUPassataRegular';

  p {
    font-family: Georgia;
  }

  >li {
    border-bottom: 1px solid #e4e4e4;
    padding: .9rem 0 !important;
    opacity: 1;
    transition-property: opacity;
    transition-duration: $list-transition-time;
    transition-timing-function: $list-transition-in;
    transition-delay: $list-transition-delay * ($list-transition-num + 1);

    @for $i from 1 through $list-transition-num {
      &:nth-child(#{$i}) {
        transition-delay: $list-transition-delay * $i;
      }
    }

    &:first-child {
      border-top: 1px solid #e4e4e4;
    }

    h2 {
      margin: 0;
      font-size: 1.636363rem;
    }

    >a {
      display: flex;
      text-decoration: none;

      h2,
      p {
        color: #0a0a0a;
      }

      p {
        margin-bottom: 0;
      }

      &:hover {
        h2 {
          color: $magenta-color;
        }
      }
    }

    div {
      padding: 0.3rem 0;

      &:first-child,
      &:last-child {
        text-align: right;

        >span {
          display: block;
        }
      }

      &:first-child {
        flex: 0 0 auto;
        padding-right: 1.5rem;
        padding-left: 1.5rem;

        span:last-child {
          font-size: 1.636363rem;
          line-height: 1.4;
        }
      }

      &:nth-child(2) {
        flex: 1 1 auto;
        padding-right: 1.5rem;

        span {
          color: #7b7b7b;
        }
      }

      &:last-child {
        flex: 0 0 290px;

        span {
          color: #7b7b7b;
        }
      }
    }

    &.new {
      div {
        &:nth-child(2) {
          span:first-child {
            &::before {
              content: 'Ny';
              display: inline-block;
              background-color: $staff-color;
              color: #fff;
              padding: 2px 4px;
              margin-right: .5rem;
              line-height: 1;
              text-transform: uppercase;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }

  &.updating>li {
    opacity: .5;
    transition-delay: 0ms;
    transition-duration: 0ms;
    transition-timing-function: linear;
  }
}

html[lang="en"] .au-vacancies-list>li.new div:nth-child(2) span:first-child::before {
  content: 'New'
}

.au-vacancies-jobagent {
  display: flex;
  flex-wrap: wrap;
  padding: 2.72727273rem 0 0 2.72727273rem;
  background-color: rgba(95, 0, 48, 1);
  background-size: cover;
  margin-bottom: 2.7272723rem;

  a.button {
    margin: 0;
    text-transform: uppercase;
    font-size: 1.2727273rem;
    flex-grow: 1;

    &:hover {
      background-color: #e2007a !important;
    }
  }

  >* {
    align-self: center;
    margin: 0 2.72727273rem 2.72727273rem 0 !important;
  }

  div {
    font: 1.4rem 'AUPassataRegular';
    color: #fff;
    flex-grow: 1;

    h3 {
      margin-top: 0;
    }

    p {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1023px) {
  .au-vacancy-filter-container {
    background-image: url('https://cdn.au.dk/apps/react/vacancies/latest/jobs-narrow.svg');

    &.empty {
      background-image: url('https://cdn.au.dk/apps/react/vacancies/latest/empty-state-narrow.svg');
    }

    .au-vacancy-vip-tap {

      >div:first-child>button:nth-child(n+2),
      >a.button {
        display: none;
      }

      >div button.vip-tap {
        display: initial;
      }

      >div button.selected {
        display: initial !important;
      }

      .au-vacancy-filter-list.mobile {
        top: 1rem;
      }
    }

    .au-vacancy-filter {
      padding: 0 1rem;

      >span {
        width: 100%;
        padding-left: 1rem;
        margin: 1rem 0;
        font-size: 1.2rem;
      }

      >div:not(.mobile) {
        >button {
          margin-bottom: 1rem;
        }
      }
    }

    .au-vacancy-filter-list {
      display: none;

      &.mobile {
        display: block;
        width: auto !important;
        left: 1rem;
        right: 1rem;
        top: 4rem;

        button.vip,
        button.tap {
          padding-left: 3.5rem;
          border-radius: 0;

          &.selected {
            color: $darkred-color;

            &::after {
              display: none;
            }

            &::before {
              color: $darkred-color;
            }
          }
        }
      }
    }
  }

  .au-vacancies-list {
    >li {
      div {
        &:last-child {
          flex-basis: 200px;
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .au-vacancies-list {
    >li {
      a {
        flex-wrap: wrap;
        align-items: flex-end;
      }

      div {

        &:first-child,
        &:last-child {
          text-align: right;
        }

        &:first-child {
          flex: 1 0 auto;
          order: 3;
          padding-right: 0;
        }

        &:nth-child(2) {
          flex: 1 100%;
          order: 1;
          padding-bottom: 0;
        }

        &:last-child {
          flex: 1 1 auto;
          order: 2;
          text-align: left;
          max-width: calc(100% - 120px);
        }
      }
    }
  }
}

#au-vacancies-social {
  position: relative;
  border-top: 1px solid #e4e4e4;
  padding-top: 30px;
}

/* Single page CSS (moved from Dycon_Emply extension) */

.au-vacancies-single-content {
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 2.727273rem;
  grid-row-gap: 2.727273rem;
  grid-template-areas: "info" "text";
  margin-bottom: 2.727273rem;
}

.au-vacancies-single-text {
  grid-area: text;
}

.au-vacancies-single-text a {
  word-break: break-all;
}

.au-vacancies-single-text a[href^="https://au.emply.net/recruitment/vacancyApply.aspx"] {
  display: none;
}

@media (min-width: 950px) {
  .au-vacancies-single-content {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "info info" "text text";
  }
}

@media (min-width: 1024px) {
  .au-vacancies-single-content {
    grid-template-columns: auto 340px;
    grid-template-rows: auto auto 1fr;
    grid-template-areas: "text info" "text ." "text .";
  }
  .au-vacancies-vcard {
    align-self: start;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .au-vacancies-single-content {
    display: -ms-grid;
    -ms-grid-columns: auto 340px;
    -ms-grid-rows: auto 1fr auto auto;
  }

  .au-vacancies-single-text {
    -ms-grid-row: 1;
    -ms-grid-row-span: 4;
    -ms-grid-column: 1;
    margin-right: 2.727273rem;
  }

  .au-vacancies-infobox {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }

  .au-vacancies-vcard {
    display: block !important;
    margin-top: 2.727273rem;
    -ms-grid-row: 3;
    -ms-grid-column: 2;
  }

  .au-vacancies-vcard + .au-vacancies-vcard {
    -ms-grid-row: 4;
  }
}

.au-vacancies-single-intro {
  margin-bottom: 2.727273rem;
}

.au-vacancies-single-intro>p {
  font-size: 1.8rem;
  line-height: 1.4;
}

.au-vacancies-single ul {
  overflow: hidden;
}

.au-vacancies-single-intro>span {
  font: 1.63636364rem 'AUPassataRegular';
  color: rgb(123, 123, 123);
}

.au-vacancies-infobox {
  padding: 1.81818182rem 1.81818182rem 2.72727273rem 1.81818182rem;
  background: rgba(0, 62, 92, 1) url('https://cdn.au.dk/apps/react/vacancies/latest/information.svg');
  background-size: cover;
  color: #fff;
  font-family: 'AUPassataRegular';
  grid-area: info;
  align-self: start;
}

.au-vacancies-infobox strong {
  font-weight: normal;
  font-family: 'AUPassataBold';
}

.au-vacancies-infobox>div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, .3);
  margin-bottom: 2.3636364rem;
}

.au-vacancies-infobox>div p {
  display: flex;
  flex-direction: column;
}

.au-vacancies-infobox>div p span:first-child {
  flex-grow: 1;
  line-height: 1.1;
}

.au-vacancies-infobox>div p:nth-child(1) {
  text-align: left;
}

.au-vacancies-infobox>div p:nth-child(2) {
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
}

.au-vacancies-infobox>div p:nth-child(3) {
  text-align: right;
}

.au-vacancies-infobox span {
  display: block;
}

.au-vacancies-infobox span.date {
  font-size: 1.63636364rem;
  white-space: nowrap;
}

.au-vacancies-infobox span.id {
  color: rgba(255, 255, 255, .6);
  display: inline-block;
  margin-top: 1rem;
}

.au-vacancies-infobox ul {
  margin-bottom: 2.1818182rem;
}

.au-vacancies-infobox ul>li {
  padding-left: 4.54545455rem !important;
  position: relative;
}

.au-vacancies-infobox ul>li::before {
  position: absolute;
  font: 3rem 'font-awesome' !important;
  color: #fff;
}

.au-vacancies-infobox ul>li:first-child::before {
  left: 5px;
  content: '\f015';
}

.au-vacancies-infobox ul>li:last-child::before {
  left: 3px;
  content: '';
}

.au-vacancies-infobox a.button,
.au-vacancies-apply a.button {
  margin: 0;
  text-transform: uppercase;
  font-size: 1.2727273rem;
}

.au-vacancies-infobox a.button {
  width: 100%;
}

.au-vacancies-infobox a.button:hover,
.au-vacancies-apply a.button:hover {
  background-color: #2b81a4 !important;
}

.au-vacancies-apply {
  display: flex;
  flex-wrap: wrap;
  padding: 2.72727273rem 0 0 2.72727273rem;
  background: rgba(0, 62, 92, 1) url('https://cdn.au.dk/apps/react/vacancies/latest/work-at-au.svg');
  background-size: cover;
  margin-bottom: 2.7272723rem;
}

.au-vacancies-apply>* {
  align-self: center;
  margin: 0 2.72727273rem 2.72727273rem 0 !important;
}

.au-vacancies-apply a.button {
  flex-grow: 1;
}

.au-vacancies-apply p {
  font: 1.63636364rem 'AUPassataRegular';
  color: #fff;
  flex-grow: 1;
}

.au-vacancies-vcard {
  background-color: rgb(242, 242, 242);
  padding: 2.18181812rem;
  font-family: 'AUPassataRegular';
}

.au-vacancies-vcard strong {
  font-weight: normal;
  font-family: 'AUPassataBold';
}

.au-vacancies-vcard h2 {
  margin-top: 0 !important;
  width: 100%;
  font: 1.6rem 'AUPassataRegular';
  margin-bottom: 1rem !important;
}

.au-vacancies-vcard p span {
  display: block;
}

.au-vacancies-vcard p span.contact-option {
  margin: 1rem 0 0 0;
}

.au-vacancies-vcard img {
  float: right;
  width: 90px;
  align-self: baseline;
  margin-left: 1rem;
}

@media (max-width: 640px) {
  .au-vacancies-infobox {
    float: none;
    width: auto;
  }
  .au-vacancies-apply>* {
    width: 100%;
  }
  .au-vacancies-apply a.button {
    margin-right: 0;
    margin-bottom: 2rem;
  }
  .au-vacancies-vcard {
    float: none;
    width: auto;
  }
}

.aubanner.au-info, .aubanner.au-about {
  min-height: 212px;
}

.aubanner.au-info h2 {
  font-family: 'AUPassataLight';
  display: block;
  font-size: 36px;
  margin: 0;
  padding: .5rem 0 .5rem 0;
}

.aubanner.au-info h2 strong {
  font-family: 'AUPassataBold';
}

.aubanner.au-info::after {
  display: none;
}

.aubanner.au-info .aubanner-content {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.aubanner.au-info:hover::before {
  opacity: 0 !important;
}

#au-vacancies-social {
  position: relative;
  border-top: 1px solid #e4e4e4;
  padding-top: 30px;
}

@media print {
    .au-vacancies-single-content {
        display: block;
    }
    .au-vacancies-vcard {
        border: 1px solid black;
        float: left;
        width: calc(50% - .75rem);
        margin-right: .75rem;
        margin-bottom: 1.5rem;
    }
    .au-vacancies-vcard:last-of-type {
        margin-left: .75rem; margin-right: 0;
    }
    .au-vacancies-infobox {
        border: 1px solid black;
        background: rgb(242, 242, 242);
        float: right;
        margin-left: 1.5rem;
        margin-bottom: 1.5rem;
    }
    .au-vacancies-infobox ul>li::before {
        color: black;
    }
    .aubanner, .au-vacancies-facts, .au-vacancies-apply, .au-vacancies-infobox a.button, .neutral-container
    {
        display: none;
    }
  }