// FONTS
@font-face {
  font-family:'AUPassataBold';
  src:url('https://fonts.au.dk/fonts/aupassata_bold-webfont.eot'); /* IE9 Compat Modes */
  src:url('https://fonts.au.dk/fonts/aupassata_bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://fonts.au.dk/fonts/aupassata_bold-webfont.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://fonts.au.dk/fonts/aupassata_bold-webfont.woff') format('woff'), /* Pretty Modern Browsers */
  url('https://fonts.au.dk/fonts/aupassata_bold-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://fonts.au.dk/fonts/aupassata_bold-webfont.svg#AUPassataBold') format('svg'); /* Legacy iOS */
  font-weight:normal;
  font-style:normal;
}

@font-face {
  font-family:'AUPassataLight';
  src:url('https://fonts.au.dk/fonts/aupassata_light-webfont.eot'); /* IE9 Compat Modes */
  src:url('https://fonts.au.dk/fonts/aupassata_light-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://fonts.au.dk/fonts/aupassata_light-webfont.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://fonts.au.dk/fonts/aupassata_light-webfont.woff') format('woff'), /* Pretty Modern Browsers */
  url('https://fonts.au.dk/fonts/aupassata_light-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://fonts.au.dk/fonts/aupassata_light-webfont.svg#AUPassataLight') format('svg'); /* Legacy iOS */
  font-weight:normal;
  font-style:normal;
}

@font-face {
  font-family:'AUPassataRegular';
  src:url('https://fonts.au.dk/fonts/aupassata_rg-webfont.eot'); /* IE9 Compat Modes */
  src:url('https://fonts.au.dk/fonts/aupassata_rg-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://fonts.au.dk/fonts/aupassata_rg-webfont.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://fonts.au.dk/fonts/aupassata_rg-webfont.woff') format('woff'), /* Pretty Modern Browsers */
  url('https://fonts.au.dk/fonts/aupassata_rg-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://fonts.au.dk/fonts/aupassata_rg-webfont.svg#AUPassataRegular') format('svg'); /* Legacy iOS */
  font-weight:normal;
  font-style:normal;
}